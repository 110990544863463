import React from "react";
import moment from 'moment-timezone'
import { Link } from "react-router-dom";
const EXT_VIDEO = /\.(avi|wmv|mpeg|webm|mov|mpg|divx|mpeg-2|3gp|flv|swf|m4v|ogv|mkv|mjpeg|vob|xvid|mts|av1|ts|avchd|hevc|asf|mxf|m2v|3g2|rmvb|m2ts|wtv|f4v|rm|mp4)$/i;

const filmIdList = JSON.parse(process.env.REACT_APP_JOIN_THE_LOBBY_HIDE)
// ["61c41b0cf8c0f02181335f15","61ecaaccde9bd57c705299cf"]
const FilmCard = (props) => {
    // constructor(props) {
    //   super(props)
    //   this.state={
    //   }
    // }

    // render() {
    const screen = props.data.screenId;
    const film = props.data.filmId;
    const reDirect = `/program-detail/${screen && screen._id}${screen && screen.ButtonText === 'Watch Q&A Replay' ? '/qa' : ''}`
    const getButtonText = (TYPE) => {
        switch (TYPE) {
            case 1: return 'Learn More'
            case 2: return 'Join the Lobby'
            case 3: return 'Watch Now'
            case 4: return 'Watch Q&A Replay'
            case 5: return 'Watched'
            case 6: return 'Screening Passed'
            case 7: return 'This Screening is not available in your country.'
            case 8: return 'Screening Passed'
            default: break;
        }
    }
    const getButton = (screen) => {
        if (screen.ButtonText === 7) {
            return <span>{getButtonText(screen.ButtonText)}</span>
        } else if (screen.ButtonText === 1 || screen.ButtonText === 8) {
            return <></>
        } else if (screen.ButtonText === 2 && filmIdList.includes(film._id) ) {
            return <></>
        } else {
            return <Link className={screen.ButtonText === 5 ? `sd_events_none watched disabled` : screen.ButtonText === 6 ? `Passed sd_events_none disabled` : `sd_p_cursor`} to={reDirect}>{screen && getButtonText(screen.ButtonText)}</Link>
        }
    }
    return (
        <div className="film_card">
            <div className="film_card_img">
                {/* <img src={film && film.images && film.images[0].link}  alt={film.title || ''}/> */}
                <img src={film && film.images && film.images[0].inSite ? props.apiUrl + film.images[0].inSite :
                    film && film.images && film.images[0].link && !EXT_VIDEO.test(film.images[0].link) ? film.images[0].link : '/images/placeholder.png'} alt="" />
            </div>
            <p>{screen && screen.screeningDate ? moment(screen.screeningDate).format('MMMM DD, hh:mm A') : ''}</p>
            <h3>{film.title || ''}</h3>
            {getButton(screen)}

        </div>
    );
    // }
}
export default FilmCard;