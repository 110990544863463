import React, { Component } from "react";

export default class CheckBox extends Component {
  render() {
    const { label, name, type, id, checked, value, disabled } = this.props;
    return (
      <div className="checkbox_field_row">
        <div className="ac_checkBox">
          <input
            type={type}
            id={id}
            name={name}
            checked={checked}
            value={value}
            disabled={disabled}
            onChange={(e) => this.props.onChange(e)}
          />
          <label htmlFor={id}>{label}</label>
        </div>
      </div>
    );
  }
}
