import React, { Component } from 'react'

// Slick Slider - Package
import Slider from "react-slick";
import service from '../common/service'

// Components
import VideoPlayer from "../components/VideoPlayer";
const EXT_IMAGES = /\.(svg|png|apng|jpeg|jpg|ico|hdr|bmp|webp|gif|tiff|tif|jp2|rgb|dds|cur|tga|heic|jfif|pgm|jpe|xpm|exr|map|ppm|g3|wbmp|jbg|pcx|pdb|avif|jps|rgba|heif|fax|xbm|yuv|pcd|pict|xwd|pal|vips|pgx|pbm|jbig|picon|ras|pct|fts|hrz|ipl|mng|mtv|otb|palm|pam|pfm|pnm|rgbo|sgi|sun|uyvy|viff|xv|g4|rgf|six|sixel|jif|jfi)$/i;
const BASE_64 = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/
const EXT_VIDEO = /\.(avi|wmv|mpeg|webm|mov|mpg|divx|mpeg-2|3gp|flv|swf|m4v|ogv|mkv|mjpeg|vob|xvid|mts|av1|ts|avchd|hevc|asf|mxf|m2v|3g2|rmvb|m2ts|wtv|f4v|rm|mp4)$/i;
class ProgramDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.apiserve = new service();
    }
    componentDidMount() {
        this.cursorOutVideo();
    }

    cursorOutVideo() {
        // Hide video controls on no activity (5 seconds)
        let video = document.querySelector(".sd_video_wpr"),
            timeout;
        if (video) {
            video.addEventListener("mousemove", function () {
                if (document.querySelector(".video-react-control-bar")) {
                    document.querySelector(".video-react-control-bar").classList.remove("video-react-control-out");

                    timeout = setTimeout(function () {
                        document.querySelector(".video-react-control-bar").classList.add("video-react-control-out");
                    }, 5000);

                    clearTimeout(timeout);
                }
            });
        }
    }

    validateYouTubeUrl = (url) => {

        if (url !== undefined || url !== '') {
            // eslint-disable-next-line
            var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            var match = url.match(regExp);
            if (match && match[2].length === 11) {
                return true
            }
            else {
                return false
            }
        }
    }

    render() {

        // Tabs slider Configuration
        const sliderConfig = {
            dots: true,
            infinite: false,
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
        }
        const { film } = this.props
        var self = this;
        return (
            <div class="sd_films_prgoram_details">
                <div class="sd_prgoram_details_desc">

                    <div class="sd_prgoram_details_desc_block">
                        <span className="sd_textuppercase">FILM</span>
                        <h2 className="sd_textuppercase">{film && film.title}</h2>
                    </div>

                    <div className="sd_rtf_content">
                        <p dangerouslySetInnerHTML={{ __html: film && film.description }}    ></p>
                    </div>

                </div>

                {/* <Slider className="sd_prgoram_details_slider" {...sliderConfig}>
                    <div className="sd_prgoram_details_block sd_video_wpr"> 
                        <VideoPlayer
                            videoSrc="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4"
                            autoplay={false}
                            loop={true}
                        />
                    </div>
                    <div className="sd_prgoram_details_block"> 
                        <img src="https://dummyimage.com/1000x1000" alt="Tab title" title="Tab title"/>
                    </div>
                    <div className="sd_prgoram_details_block"> 
                        <img src="https://dummyimage.com/1000x1000" alt="Tab title" title="Tab title"/>
                    </div>
                </Slider> */}
                <div className='sd_main_img'>
                    <div class="sd_film_img_title">
                        <span className="sd_textuppercase">FILM IMAGES</span>
                    </div>
                    <Slider className="sd_prgoram_details_slider" {...sliderConfig}>

                        {film && film.images && film.images.length &&
                        // eslint-disable-next-line
                            film.images.map((item, index) => {
                                console.log(item,"::::item", BASE_64.test(item.inSite || item.link));
                                if (item.inSite || item.link) {
                                    var isEmbeded = this.validateYouTubeUrl(item.inSite || item.link)
                                    return (
                                        <div>
                                            {(EXT_IMAGES.test(item.inSite || item.link) || (!EXT_IMAGES.test(item.inSite || item.link) && !EXT_VIDEO.test(item.inSite || item.link))) ? (
                                                <div className="sd_prgoram_details_block">
                                                    <img src={item.inSite ? this.apiserve.imageUrl + item.inSite : item.link ? item.link : '/images/image-placeholder.png'} alt="" />
                                                </div>
                                            ) 
                                                :
                                                EXT_VIDEO.test(item.inSite || item.link) ? (
                                                    <div className="sd_prgoram_details_block sd_video_wpr">
                                                        (
                                                        <>
                                                            <VideoPlayer
                                                                autoplay={false}
                                                                loop={true}
                                                                videoSrc={item.inSite ? self.apiserve.imageUrl + item.inSite : item.link ? item.link : 'https://dummyimage.com/1000x1000'}
                                                            >
                                                            </VideoPlayer>
                                                        </>
                                                        )
                                                    </div>
                                                ) : isEmbeded
                                                        ? <>
                                                        <div className="sd_prgoram_details_block sd_video_wpr">
                                                            <iframe src={item.inSite || item.link} title={index}></iframe>
                                                        </div>
                                                        </>
                                                        : ''
                                            }
                                        </div>
                                    )
                                }
                            })}
                    </Slider>

                </div>
            </div>
        )
    }
}
export default ProgramDetails;