import React, { Component } from 'react';

// Components 
import QaMessage from './QaMessage';

class QaLive extends Component {
    render() {
        return (
            <>
                <div className="sd_chat_content_wpr">
                    <QaMessage />
                    <QaMessage />
                    <QaMessage moderator={true}/>
                    <QaMessage />
                    <QaMessage /> 
                    <QaMessage />  
                </div>

                <div className="sd_chat_add">
                    <form>
                        <div className="sd_form_group">
                            <textarea placeholder="Submit your question" rows="2" maxLength={280}/>
                        </div>
                        <div className="sd_btn_group">
                            <button className="sd_btn sd_btn_black">Submit</button>
                        </div>
                    </form>
                </div>  
            </>
        )
    }
}

export default QaLive;