import { Component } from "react";
import io from "socket.io-client";
import { Observable } from "rxjs";
import CryptoJS from "crypto-js";
import alertify from "alertifyjs";

let socket;

class service extends Component {
  apiURL = process.env.REACT_APP_API_KEY;
  socketApi = process.env.REACT_APP_SOCKET;
  imageUrl = process.env.REACT_APP_IMAGE_URL;

  connect = async (next) => {
    const socketApi = process.env.REACT_APP_SOCKET;
    socket = io.connect(socketApi);
    socket.on("connect", (res) => {
      console.log('res', socket)
      next(socket.connected);
    });
  };

  sendevent = (e, Data) => {
    if(process.env.REACT_APP_DISABLE_ENCRYPTION === 'true')
    {
      return socket.emit("request", { event: e, data: Data });
    }
    else
    {
      return socket.emit("request", this.encrypt({ event: e, data: Data }));
    }
  };

  getevent = () => {
    var self = this;
    return Observable.create((observer) => {
      
        if(process.env.REACT_APP_DISABLE_ENCRYPTION === 'true')
        {
          socket.on("response", (data) => {
            observer.next(data);
          });
        }
        else
        {
          socket.on("response", async (data) => {
            var responseData = await self.decrypt(data)
            observer.next(responseData);
          });
        }
     
      socket.on("error", (data) => {
        console.log(data);
      });
    });
  };

  removeLocalstorage = () => {
    localStorage.removeItem("loginToken");
  };

  encrypt = (data) => {
    return CryptoJS.AES.encrypt(
      JSON.stringify(data),
      this.DPro(this.DPro(process.env.REACT_APP_SECRET_KEY))
    ).toString();
  };

  success = (msg) => {
    alertify.success(msg);
  };

  error = (msg) => {
    alertify.error(msg);
  };

  DPro = (data) => {
    // eslint-disable-next-line
      const bytes = CryptoJS.AES.decrypt(data, 'M+H)E?B\y8v5s2pXXp2s5v8y/B?E(H+M');

      try {
          return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      } catch (error) {
          return []
      }
  };


  decrypt = (data) => {
    return new Promise((resolve, reject) => {
      const bytes = CryptoJS.AES.decrypt(data, this.DPro(this.DPro(process.env.REACT_APP_SECRET_KEY)));
      // var bytes = CryptoJS.AES.decrypt(data,process.env.REACT_APP_SECRET_KEY);
      var decryptedData = []

      try {
        decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      } catch (error) {
        decryptedData = []
      }

      return resolve(decryptedData);
    });
  };
}

export default service;

