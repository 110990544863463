import React, {useEffect, useState} from 'react';
import { useParams} from 'react-router-dom';

// Components
// import VideoPlayer from "./VideoPlayer";
// import InfoPopup from './infoPopup';
import ShortPopup from './ShortPopup';
import service from "../common/service";
import {useDispatch, useSelector} from "react-redux";
// import AudioPopup from "./AudioPopup";
import MovieStatus from "./movie-status";
import * as workerTimers from 'worker-timers';
import Cookies from 'universal-cookie';
import moment from 'moment';
import {CONSTANTS} from '../common/constant'
import { useHistory } from 'react-router-dom';

// import Chat from '../components/Chat';
// import QaLive from '../components/QaLive';

const Service = new service();
const cookies = new Cookies();

const URL = `${process.env.REACT_APP_SHIFT_URL}/play/#/film/`

let current = 0;
let timeout;
let standardTimeout;
let start = -1;
const FilmVideo = React.memo((props) => {
    const dispatch = useDispatch()
    const {id} = useParams();
    const _FILM = useSelector(e => e.core);
    const _AUDIO = useSelector(e => e.audio) || false
    const _AGE = useSelector(e => e.age)
    const _SP = useSelector(e => e.showPlayer)
    const _STATUS = useSelector(e => e.status)
    const _MOVIE_STATUS = useSelector(e => e.movieStatus)
    const _C = useSelector(e => e.core)
    const _IS_MORE_TIME = useSelector(e => e.isMoreTime)
    const history = useHistory()
    const FIVE_HR = useSelector(e => e.fiveHours)
    const VIDEO_TYPE = useSelector(e => e.videoPlayerType)
    // const [audioPopup, setAudioPopup] = useState(false)
    const [popup, setPopup] = useState(false); // with-rating, without-rating
    console.log(props,"peops");
    useEffect(() => {
        if (props.shiftId) {
            current = 0;
            window.removeEventListener('message', onMessage);
            window.addEventListener('message', onMessage);
        }
        return () => {
            if (props.shiftId) window.removeEventListener('message', onMessage);
            if (standardTimeout) workerTimers.clearTimeout(standardTimeout)
        }
        // eslint-disable-next-line
    }, [props.shiftId]);

    const onMessage = (e) => {
        console.log(';;;;;;;;;:::;;;-',e.data.event, e);
        // if (VIDEO_TYPE === 2) { 
            return onEventiveMessage(e) 
        // } 

        // if (e.data === 'ended') {
        //     if (_STATUS === 'Now Playing') setPopup(true);
        // } else if (typeof e.data === 'object') {
        //     switch (e.data.type) {
        //         case 'ended':
        //             // if (_MOVIE_STATUS === 'continuous') setPopup(true)
        //             break;
        //         case 'timeupdate':
        //             if (_STATUS === 'Now Playing') {
        //                 if (start === -1 && e.data.currentTime > 1200) {
        //                      start = e.data.currentTime
        //                      if(_FILM?.screen?._id)
        //                      Service.sendevent('privateScreeningPartner|screeningTrack|create', {screenId : _FILM?.screen?._id, TwentyMinFlag:true});
        //                     }
        //             }
        //             if ((current - e.data.currentTime) > 2 || (current - e.data.currentTime) < -2) {
        //                 const data = {
        //                     progress: e.data.currentTime,
        //                     shift72Id: props.shiftId,
        //                     filmId: _FILM?.screen?._id
        //                 }
        //                 Service.sendevent('digitalscreening|alive', data)
        //             }
        //             current = e.data.currentTime
        //             if (!timeout) {
        //                 const data = {
        //                     progress: e.data.currentTime,
        //                     shift72Id: props.shiftId,
        //                     filmId: _FILM?.screen?._id
        //                 }
        //                 Service.sendevent('digitalscreening|alive', data)
        //               //  setPopup(false)
        //                 timeout = workerTimers.setTimeout(() => {
        //                     workerTimers.clearTimeout(timeout)
        //                     timeout = undefined
        //                 }, 14000)
        //             }
        //             break;
        //             default:
        //                 break
        //     }
        // }
    }

    const onEventiveMessage = (e) => {
        console.log(':::;;;',e.data.event);
        if (e.data.event === 'ended') {
            if (_STATUS === 'Now Playing') setPopup(true);
        } else if (typeof e.data === 'object') {
            switch (e.data.event) {
                case 'ended':
                    // if (_MOVIE_STATUS === 'continuous') setPopup(true)
                    break;
                case 'custom_20minutethreshold':
                    if (_STATUS === 'Now Playing') {
                        if (start === -1) {
                            //  start = e.data.currentTime/
                             if(_FILM?.screen?._id)
                             Service.sendevent('privateScreeningPartner|screeningTrack|create', {screenId : _FILM?.screen?._id, TwentyMinFlag:true});
                            }
                    }
                    break;
                case "progress": 

                        if(e.data.currentTime >= e.data.duration - 15)
                        {
                            console.log('CurrentTime:::', e.data.currentTime);
                            console.log('Duration:::', e.data.duration);
                            if (_STATUS === 'Now Playing') setPopup(true);
                        }
                        const data = {
                            progress: e.data.currentTime,
                            shift72Id: props.shiftId,
                            filmId: _FILM?.screen?._id
                        }
                        Service.sendevent('digitalscreening|alive', data)
                    break;
                case "ticket_expired":
                    dispatch({type: 'FIVE_HOURS_COMPLETE', payload: true});
                    
                    break;
                default:
                    break
            }
        }
    }
    
    const getURL = () => {
        const t = props.progress !== 0 ? `&t=${props.progress}` : ''
        if ((FIVE_HR === 'Started' && _AGE === 'verified' ) || _STATUS === 'Coming Soon') return _SP && `${URL}${props.shiftId}?p=${props.token}${t}&nav=false`
    }
    const getEventiveURL = () => {
        // const t = props.progress !== 0 ? `&t=${props.progress}` : ''
        let token = sessionStorage.getItem(id) || props.movieToken
        if ((FIVE_HR === 'Started' && _AGE === 'verified' ) || _STATUS === 'Coming Soon') return _SP && _STATUS === 'Coming Soon' ? props.token : token
    }
    // eslint-disable-next-line
    const playMovie = () => {
        if (_AGE === 'verified') {
            dispatch({type: 'SET_AUDIO', payload: !_AUDIO})
            Service.sendevent('userScreening|getPlayToken', {screeningId : _FILM?.screen?._id, audio : !_AUDIO || false});
        } else if (_AGE !== 'verified' && _AGE !== '') dispatch({type: 'AGE_VERIFICATION', payload: 'checking'})
    }
    const startQnA = () => {
        if (_MOVIE_STATUS === 'continuous' && _C?.screen?.qaReplayLink) {
            dispatch({type: 'SHOW_QNA', payload: true})
            cookies.set("fromTrailer", {_self : true}, {domain: process.env.REACT_APP_COOKIE_DOMAIN, path: "/", expires: new Date(moment(_C.date).add(CONSTANTS.JOIN_EXPIRES, 'minutes')) });
            if(_FILM?.screen?._id)
             Service.sendevent('privateScreeningPartner|screeningTrack|create', {screenId : _FILM?.screen?._id, JoinedQALive:true});
        }else{
            dispatch({type: 'SHOW_QNA', payload: false})
            history.push('/') 
        }
        dispatch({type: 'SET_STATUS', payload: 'End'})
        props.refresh();
        if (_MOVIE_STATUS === 'continuous' && _C?.screen?.qaReplayLink)  props.watchQA(true);
    }
    return (
        <React.Fragment>
            {
            <MovieStatus endDate={_C.screen?.screeningendDate} runTime={_FILM?.screen?.filmId?.runTime} filmDate={_FILM?.screen?.screeningDate} showPopup={e => setPopup(e)}/>}
            <div className={`sd_video_screen_wpr ${_STATUS === 'Now Playing' ? 'fullscreen' : ''}`}>
                <div className="sd_main_video_screen">
                    <div className="sd_video_screen">

                        { (props.token.length !== 0 && props.shiftId.length !== 0 && _STATUS !== 'End') || _IS_MORE_TIME ?
                        <div className="vid_wrapper sd_video_wpr">
                            {console.log(getEventiveURL(),"getEventiveURL")}
                            {/* <iframe src={VIDEO_TYPE === 1 ? getURL() : getEventiveURL()} title="Film Title" allow="autoplay; fullscreen; encrypted-media" allowFullScreen/> */}
                            <iframe src={getEventiveURL()} title="Film Title" allow="autoplay; fullscreen; encrypted-media" allowFullScreen/>
                        </div>
                        : ''}
                        {_STATUS === 'End' && !_IS_MORE_TIME ? <div className="vid_wrapper sd_video_wpr">
                            <iframe src={_FILM?.screen?.qaReplayLink ? _FILM?.screen?.qaReplayLink + '?rel=0&autoplay=1'  : ''} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title='1'/>
                        </div> :''}


                    </div>
                        {_STATUS === 'Now Playing' && popup &&
                            <div className="sd_video_screen_popup"><ShortPopup startQnA={startQnA} _C={_C} hide_close={true}  _STATUS={_STATUS} _MOVIE_STATUS={_MOVIE_STATUS} closePopup={(e) => setPopup(false)}/></div>
                        }

                </div>
            
                {/* {_SP && <div className="sd_video_screen_desc">
                    <div className="sd_video_link sd_video_screen_link">
                      {_STATUS === 'Now Playing' && <a href={null} onClick={startQnA}>
                                {_MOVIE_STATUS === 'continuous' ? "Join the Live Q&A" : 'Watch the Q&A'}
                                <img src="/images/slider_arrow_right_pink.svg" alt="Q&A" title="Q&A"/>
                            </a>}
                    </div>
                    { (_STATUS === 'Now Playing') &&
                         <div className="sd_video_audio_info">
                         <p>The use of cameras, cell phones, or recording devices is strictly prohibited. This work is imprinted with forensic and
                             visible
                             watermarking to trace illegal recordings. Thank you for protecting the work of our filmmakers.</p>
 
                         <p>Please Note: Once you begin watching, you have four hours to complete the film.</p>
                         </div>
                    }
                   

                    <div className="sd_video_audio sd_video_screen_link">
                        {_FILM?.screen?.filmId?.shift72Id.length !== 0 && _FILM?.screen?.filmId?.isAudioDescription && _FILM?.screen?.filmId?.audioDescriptionShift72Id.length !== 0 && _STATUS === 'Now Playing' &&
                         <React.Fragment>
                         <img src={_AUDIO ? "/images/no_audio_desc_icon.svg" : "/images/audio_desc_small_icon.svg"}
                              alt="Watch without Audio Description" title="Watch without Audio Description"/>
                         <a href={null} onClick={playMovie}>
                             Watch {_AUDIO ? 'Without' : 'With'} Audio Description
                             <img src="/images/slider_arrow_right_pink.svg" alt="Watch without Audio Description"
                                  title="Watch without Audio Description"/>
                         </a>
                     </React.Fragment>}
                    </div>
                </div>} */}
            </div>
            {/* {_STATUS === 'Now Playing' && audioPopup && <AudioPopup closePopup={(e) => setAudioPopup(false)}/>} */}
        </React.Fragment>
    )
})
export default FilmVideo;