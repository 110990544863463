import React from "react";

function arrowRightIcon() {
	return (
		<svg width="32px" height="26px" viewBox="0 0 32 26" version="1.1" xmlns="http://www.w3.org/2000/svg">
			<title>Fill 1</title>
			<g id="PROGRAM" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
				<g id="FPG-01b-HOME-2" transform="translate(-1618.000000, -292.000000)" fill="#000000">
					<g id="Group-13" transform="translate(30.000000, 292.000000)">
						<polygon id="Fill-1" transform="translate(1604.000000, 13.000000) scale(-1, 1) rotate(90.000000) translate(-1604.000000, -13.000000) " points="1601.67993 -3 1601.67993 20.2084211 1594.30975 12.9326316 1591 16.1666526 1603.99983 29 1617 16.1666526 1613.69059 12.9326316 1606.32041 20.2084211 1606.32041 -3"></polygon>
					</g>
				</g>
			</g>
		</svg>
		
	);
}

export default arrowRightIcon;
