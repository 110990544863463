import React, { Component } from "react";
// import logo from "../images/logo.png";
import { Link } from "react-router-dom";

// Layout
import Footer from "../layout/Footer";

export default class NotFound extends Component {
    render() {
        return (
            <div className="notfound">
				<div className="sd_header_section">
					<div class="sd_sub_container">
						<div class=" sd_not_found_header">
							<h2 class="uppercase">404</h2>
							<div class="sd_not_found_header_desc">
								<p>We couldn’t find that page!</p>
							</div>
							<div className="nofound_content">
								<Link to="/" className="click_here">CLICK HERE</Link> <span > to go to the home page.</span>
							</div>
						</div>
					</div>
				</div>       

				<Footer />
			</div>
        )
    }
}
