import React, {useState} from 'react';
import {useSelector} from "react-redux";
import service from "../../common/service";
const Service = new service();

const QaInput = React.memo(() => {
    const _ID = useSelector(e => e._id)
    const _C = useSelector(e => e.core)
    const _C_ID = useSelector(e => e.channelId)
    const [question, setQuestion] = useState('')

    const sendQuestion = e => {
        e.preventDefault()
        if (e.keyCode && e.keyCode !== 13) return;
        const q = question.trim()
        if (q.length !== 0) {
            const data = {
                question: q,
                channelId: _C.channelId || _C_ID,
                senderId: _ID,
                screeningType: 'PrivateScreeningPartnerScreen'
            }
            Service.sendevent('userScreening|publishQuestion', data)
            setQuestion('')
        }
    }
    return (
      
            <form onSubmit={e => e.preventDefault()}>
                <div className="sd_form_group">
                    <input placeholder="Submit your question" value={question} onChange={e => setQuestion(e.target.value)} onKeyUp={sendQuestion} maxLength={280}/>
                </div>
                <div className="sd_btn_group">
                    <button className="sd_btn sd_btn_black" onClick={sendQuestion}>Submit</button>
                </div>
            </form>
    )

})

export default QaInput;