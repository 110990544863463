import React, {useState} from 'react';
import service from "../common/service";
import {useSelector} from "react-redux";
import Cookies from "universal-cookie";
const cookies = new Cookies();
const Service = new service();
const ChatInput = React.memo(() => {
    // const _ID = useSelector(e => e._id)
    const _C = useSelector(e => e.core)
    const _C_ID = useSelector(e => e.channelId)
    const [message, setMessage] = useState('')

    const sendMessage = e => {
        e.preventDefault()
        if (e.keyCode && e.keyCode !== 13) return;
        const m = message.trim()
        if (m && m.length !== 0) {
            let user = cookies.get("privateScreeningUser");
            const data = {
                message: m,
                channelId: _C.channelId || _C_ID,
                senderId: user?.user?._id,
                screeningType: 'PrivateScreeningPartnerScreen'
            }
            Service.sendevent('userScreening|publishMessage', data)
            setMessage('')
        }
    }
    return (
        <div className="sd_chat_add">
            <form onSubmit={e => e.preventDefault()}>
                <div className="sd_form_group">
                    <textarea placeholder="Your text here" rows="2" value={message} onChange={e => setMessage(e.target.value)} onKeyUp={sendMessage}  maxLength={280}/>
                </div>
                <div className="sd_form_submit sd_btn_group">
                    <button className="sd_btn sd_btn_black" onClick={sendMessage}>Submit</button>
                </div>
            </form>
        </div>
    );
})

export default ChatInput;