import React, { Component } from "react";

class PwdField extends Component {

	// Toggle Password Visibility
	togglePassword = (e) => {
		let target = e.target;
		let field = target.parentNode.closest(".field_input");
		let field_button = target.parentNode.closest(".sd_passoword_toggle");
		let input_attr = field.querySelector("input").getAttribute("type");
		if (input_attr === "password") {
			field.querySelector("input").setAttribute("type", "text");
			field_button.classList.add("sd_form_pwd_active");
		} else {
			field.querySelector("input").setAttribute("type", "password");
			field_button.classList.remove("sd_form_pwd_active");
		}
	};

	render() {
		const { placeholder, label, name, value, disable, require, readonly, error_msg, note, autoComplete } = this.props;
		return (
			<>
				<div className="field_row_inner">
					{label ?
						<label>
							{label} {require ? <span>*</span> : <span></span>}
						</label>
						:
						''
					}
					<div className="field_input relative">
						<input
							type="password"
							name={name}
							value={value}
							placeholder={placeholder}
							disabled={disable}
							readOnly={readonly}
							onChange={this.props.onChange}
							autocomplete={autoComplete}
						/>
						<button type="button" tabindex="-1" className="sd_passoword_toggle" onClick={this.togglePassword.bind(this)} >
							<img src="/images/eye_open_icon.svg" alt="Password" title="Password" className="sd_password_eye_open" />
							<img src="/images/eye_close_icon.svg" alt="Password" title="Password" className="sd_password_eye_close" />
						</button>
					</div>
				</div>
				
				<span className={`sd_input_error_msg ${note ? "sd_input_error_note" : ""}`} >
					{error_msg}
				</span>
			</>
		);
	}
}

export default PwdField;
