import React, { Component } from 'react'

class MoreInfo extends Component {
    render() {
        const {film} = this.props
        return (
            <div class="sd_films_more_info">
                <div class="sd_films_more_info_header">
                    <h2 className="sd_textuppercase">{film && film.screeningMoreInfoTitle}</h2>
                </div>
                <div class="sd_films_more_info_desc sd_rtf_content">
                     <p dangerouslySetInnerHTML={{ __html: film && film.screeningMoreInfo }}    ></p>
                </div>
            </div>
        
        )
    }
}
export default MoreInfo;