import React, {useEffect, useState} from 'react';
import QaInput from "./qa-input";
import QuestionsAnswers from "./questions-answers";
import service from "../../common/service";
import {useSelector} from "react-redux";
import usePrevious from "../../common/hooks/usePrevious";
import {CONSTANTS} from "../../common/constant";
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const Service = new service()
const QNA = React.memo(() => {
    const _Q_S = useSelector(e => e.questionStatus);
    const _S_Q = useSelector(e => e.showQnA);
    const _Q = useSelector(e=> e.questions)
    const _C = useSelector(e => e.core)
    const _C_ID = useSelector(e => e.channelId)
    const _MP = useSelector(e => e.questionPagination)
    const _JM = useSelector(e => e.jumpQuestion)

    const [bottom, setBottom] = useState(true)
    const prevState = usePrevious({bottom});

    useEffect(() => {
        if (_C?.screen?.filmId?._id) getMessages();
        // eslint-disable-next-line
    }, [_C])

    useEffect(() => {
        if (_JM && _JM.length !== 0 && prevState && prevState.bottom) {
            const objDiv = document.getElementById("live-qna");
            objDiv.scrollTop = objDiv.scrollHeight;
        }
        // eslint-disable-next-line
    }, [_JM])

    useEffect(() => {
        var objDiv = document.getElementById("live-qna");
        objDiv.scrollTop = objDiv.scrollHeight;
    }, [_Q])

    useEffect(() => {
        if (_MP && _MP.page) {
            if (_MP.page === 1) {
                const objDiv = document.getElementById("live-qna");
                objDiv.scrollTop = objDiv.scrollHeight;
            } else {
                const i = _Q.length - _MP.pagingCounter;
                if (i && _Q[i]) {
                    const questionDiv = document.getElementById(_Q[i]._id);
                    if (questionDiv) questionDiv.scrollIntoView({block: "nearest"});
                }
            }
        }
        // eslint-disable-next-line
    }, [_MP]);


    const getMessages = () => {
        let user = cookies.get("privateScreeningUser");
        Service.sendevent('userScreening|getPublishedQuestion', {
            channelId: _C.channelId || _C_ID,
            limit: CONSTANTS.PAGINATION_LIMIT,
            page: _MP?.nextPage? _MP?.nextPage : 1,
            senderId:user?.user?._id,
            screeningType: 'PrivateScreeningPartnerScreen'
        })
    }

    const handleScroll = e => {
        if (e.target.scrollTop === 0) {
             if (_MP &&_MP.hasNextPage) getMessages();
        }
        setBottom(e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight - 100);
    };

    return (
        <div className="sd_live_qa_sec">
            <div className="sd_qa_live_title">
                {/* <h2 className="sd_textuppercase">LIVE Q&amp;A</h2> */}
                {_Q_S === 2 && <p className="sd_chat-note">Live Q&A for this event is closed.</p>}
            </div>
            <div className="sd_qa_live_content" id='live-qna' onScroll={handleScroll}>
                {_Q_S === 1 && _S_Q && <React.Fragment>
                    {
                        _Q && _Q.map(item=>{
                            return(
                                 <QuestionsAnswers props={item}/>
                            )
                        })
                    }
                </React.Fragment>}
            </div>
            {_Q_S === 1 && _S_Q &&<div className="sd_chat_add"> <QaInput/></div>}
           
        </div>
    );
})

export default QNA;