import React from 'react'

export default function WatchEndPopup() {

    const backToHome =()=>{
        window.open(process.env.REACT_APP_HOME_URL + 'my-account?tab=2', '_self')
    }

    return (
        <div className="sd_popup">
            <div className="sd_popup_inner five_hr_popup">
                <p className="title sd_textcenter">Your 5-hour watch window has ended</p>
                <div className="sd_textcenter btn_wrp">
                    <button onClick={()=>backToHome()}>Back to Home</button>
                </div>
            </div>
        </div>
    )
}
