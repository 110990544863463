import React, { Component } from 'react';

class CreditName extends Component {
    
    websiteLink = (link) => {        
        let link_text = link.length > 100 ? link.substr(0, 97) + '...' : link;        
        return <a className="sd_credit_link_title" href={link} target="_blank" rel="noopener noreferrer">{link_text}</a>
    }

    render() {
        // Props
        const { pos, data } = this.props;

        return (
            <li>
                <span className="sd_film_artists_cr_pos">{pos}</span>
                <div className="sd_film_artists_cr_name">
                    {
                        data.map(name => {
                            return <p key={name}>{
                                pos === 'Website' ? 
                                    this.websiteLink(name)
                                : name
                            } </p>;
                        })
                    }
                </div>
            </li>
        )
    }
}
export default CreditName;