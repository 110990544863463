import React, { Component } from 'react';

// Layout
import Header from '../layout/Header';
import Footer from '../layout/Footer';

// Components
import ProgramDetails from '../components/ProgramDetails';
import AboutFilm from '../components/Aboutfilm';
import MoreInfo from '../components/MoreInfo';
import Chat from '../components/Chat';
import QaLive from '../components/QaLive';
import HeroHeader from '../components/HeroHeader';
import FilmVideo from '../components/FilmVideo';

export default class LiveQa extends Component {

    constructor() {
        super();
		this.state = {
            films_tab: [
                { title: 'program details', title_name: 'program_details', isActive: true }, 
                { title: 'about the film', title_name: 'about_the_film', isActive: false },
                { title: 'more info', title_name: 'more_info', isActive: false }
            ],
            active_film_tab: 'program_details'
        };		
    }
    // Switch Tabs
    switchTab = (tab_data) => {
        
        var films_tab_copy = [...this.state.films_tab]; 
        films_tab_copy.forEach((tab) => {
            tab.isActive = false;
            if(tab.title === tab_data.title) {
                tab.isActive = true;
            } 
            this.setState({
                active_film_tab: tab_data.title_name
            });
        });
                
        this.setState({
            films_tab: films_tab_copy
        });
        
    }

    render() {

        let { films_tab, active_film_tab } = this.state;

        return (
            <div>

                {/* Header */}
                <Header isMenuShow={true}/>

                {/* Hero header */}
                <HeroHeader />

                {/* Video */} 
                <div className="sd_video_screen_section">
                    <div className="sd_inner_container">
                        <FilmVideo />
                    </div>
                </div>

                {/* Brought by */}
                <div className="sd_outer_brought">
                    <div className="sd_brought_by">
                        <p>Brought to you by</p>
                        <span>
                            <img src="./images/IMDBPro_Black.svg" alt="Sponsors" title="Sponsors" />
                        </span>
                    </div>  
                </div>

                {/* Chat */}
                <div className="sd_chat_section sd_chat_qa_wrapper">
                    <div className="sd_inner_container">

                        <div className="sd_chat_wpr">
                            <div className="sd_chat_header">
                                <h2 className="sd_textuppercase">Chat</h2>
                            </div>
                            <div className="sd_chat_content">
                                <Chat />    
                            </div>
                        </div>

                        <div className="sd_qa_live">
                            <div className="sd_qa_live_title">
                                <h2 className="sd_textuppercase">LIVE Q&amp;A</h2>
                            </div>
                            <div className="sd_qa_live_content">
                                <QaLive />
                            </div>
                        </div>

                    </div>
                </div>

                {/* Tabs */}
                <div className="sd_films_tab_sec">
                    
                    <div className="sd_films_tab_inner">
                        <div className="sd_inner_container">
                            <div className="sd_films_tab_wpr">
                                <ul>
                                    {
                                        films_tab.map((tab) => {  
                                            return (
                                                <li className={`${tab.isActive ? 'active' : ''}`} key={tab.title}>
                                                    <button className={`sd_film_tab_button sd_film_tab_${tab.color}`} onClick={this.switchTab.bind(this, tab)}>
                                                        { tab.title } 
                                                    </button>
                                                </li>
                                            )
                                        })
                                    }

                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="sd_films_tab_content">
                        <div className="sd_inner_container">
                            <div className="sd_films_tab_content_wpr sd_film_artists_credits">

                                {
                                    active_film_tab === 'program_details' ? 
                                        <ProgramDetails />
                                    : ''
                                }

                                {
                                    active_film_tab === 'about_the_film' ? 
                                        <AboutFilm />
                                    : ''
                                }

                                {
                                    active_film_tab === 'more_info' ? 
                                        <MoreInfo />   
                                    : ''
                                }

                            </div>

                        </div>
                        <div className="sd_films_content_warning">
                            <p>WARNING: THIS FILM CONTAINS EXPLICIT CONTENT</p>
                        </div>
                    </div>
                </div>

                {/* Footer */}
                <Footer />

            </div>
        )
    }
}
