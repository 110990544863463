import service from "../common/service";
import { REHYDRATE } from 'redux-persist';
import Cookies from 'universal-cookie';
import { CONSTANTS } from "../common/constant";
const Service = new service()
const cookies = new Cookies();

let initState = {
    token: '',
    _id: '',
    role: '',
    user: {},
    core: {
        filmType: 'PrivateScreeningPartnerScreen', film: '', date: '', channelId: '', screen:''
    },
    film: {},
    showPlayer: false,
    showQnA:false,
    chatStatus: 0,
    messages: [],
    messagePagination: {},
    jumpMessage: '',
    questionStatus: 0,
    questions: [],
    questionPagination: {},
    jumpQuestion: '',
    explicitStatus: '',
    sponsorOffer: [],
    allSponsors: [],
    isMoreTime:false,
    age: '', // verified, not-verified, checking
    audio: true,
    status: 'Coming Soon', //Coming Soon, Premiere, Now Playing, End
    movieStatus: '', // continuous,
    isWaitingRoomRunning: false,
    fiveHours:'',
    videoPlayerType:1,
    fiveHoursComplete:false
};

function sundanceReducer(state = initState, action) {
    switch (action.type) {
        case 'AUTHENTICATION': {
            return {...state, ...action.payload.user, token: action.payload.token}
        }
        case 'USER_PROFILE': {
            return {...state, user: action.payload}
        }
        case 'SAVE_CORE_DATA': {
            return {...state, core: {...action.payload}}
        }
        case 'JOIN_CHANNEL': {
            const localState = {}
            let user = cookies.get("privateScreeningUser");
            // localState.fiveHours = 'Started' 
            localState.fiveHours = action.payload.isFiveHoursDisplay ? 'Waiting' : 'Started'
            localState.movieEventiveId = action.payload.movieEventiveId
            localState.myTicketId = action.payload.myTicketId
            // if (state?.core?.screen?.filmId?.isExplicitContent && user) {
            //     const data = {user: user?.user?._id, filmId: state?.core?.screen?._id, filmType: 'PrivateScreeningPartnerScreen'}
            //     Service.sendevent('userScreening|getAgeVerification', data)
            // } else {
                localState.age = 'verified'
            // }
            localState.isWaitingRoomRunning = action.payload.isWaitingRoom
            localState._id = user?.user?._id
            localState.film = action.payload
            // localState.audio = !(state?.core?.screen?.filmId?.shift72Id.length !== 0 ? true : !state?.core?.screen?.filmId?.isAudioDescription)
            localState.core ={...state.core, channelId: action.payload.channelId}
            state.channelId = action.payload.channelId;
            return {...state, ...localState}
        }
        case 'CHANNEL_STATUS': {
            return {...state, chatStatus: action.payload.chatStatus, questionStatus: action.payload.questionStatus}
        }
        case 'FIVE_HOURS_COMPLETE': {
            return {...state, fiveHoursComplete: action.payload}
        }
        case 'PUBLISHED_MESSAGES': {
            const messages = state.messages ? JSON.parse(JSON.stringify(state.messages)) : [] || []
            const localState = {}
            console.log(messages);
             if (action.payload.page === 1) localState.messages = action?.payload?.docs ? action?.payload?.docs:  [] 
             else if(action?.payload?.docs) {
                 localState.messages = [...action.payload.docs, ...messages]
                }

             localState.messagePagination = action.payload
            return {...state, ...localState}
        }
        case 'PUBLISH_MESSAGE': {
            const messages = state.messages ? JSON.parse(JSON.stringify(state.messages)) : [];
            const localState = {}
            if (action.payload.channelId === state.core.channelId) {
                messages.push(action.payload)
                if (messages.length > CONSTANTS.MAX_LENGTH) messages.shift()
                localState.messages = messages
                localState.jumpMessage = action.payload._id
            }
            return {...state, ...localState}
        }
        case 'DELETE_MESSAGE': {
            const messages = state.messages? JSON.parse(JSON.stringify(state.messages)) : [] || []
            const localState = {}
            localState.messages = messages.filter(e => e._id !== action.payload.messageId);
            return {...state, ...localState}
        }
        case 'PUBLISHED_QUESTIONS': {
            const questions = state.questions ? JSON.parse(JSON.stringify(state.questions)) : [] || []
            const localState = {}

            if (action.payload.page === 1) localState.questions = action?.payload?.docs ? action?.payload?.docs : []
            else if(action?.payload?.docs) localState.questions = [...action.payload.docs, ...questions]

            localState.questionPagination = action.payload
            return {...state, ...localState}
        }
        case 'QUESTION_LIKE': {
            const questions = state.questions ? JSON.parse(JSON.stringify(state.questions)) : [] ||[]
            const localState = {}
            localState.questions = questions.map(e => {
                if (e._id === action.payload.questionId) e.totalLikes = action.payload.totalLikes
                return e
            })
            return {...state, ...localState}
        }
        case 'PUBLISH_QUESTION': {
            const questions = state.questions ? JSON.parse(JSON.stringify(state.questions)) : []
            const localState = {}
            if (action.payload.channelId === state.core.channelId) {
                questions.push(action.payload)
                state.channelId = action.payload.channelId;
                localState.questions = questions
                localState.jumpQuestion = action.payload._id
            }
            return {...state, ...localState}
        }
        case 'DELETE_QUESTION': {
            const questions = state.questions ?  JSON.parse(JSON.stringify(state.questions)) : [] || []
            const localState = {}
            localState.questions = questions.filter(e => e._id !== action.payload.questionId);
            return {...state, ...localState}
        }
        case 'SPONSOR_OFFERS': {
            return {...state, sponsorOffer: action.payload.sponsorOffer}
        }
        case 'AGE_VERIFICATION': {
            return {...state, age: action.payload}
        }
        case 'PLAYER_STATUS': {
            return {...state, showPlayer: action.payload}
        }
        case 'SHOW_QNA': {
            return {...state, showQnA: action.payload}
        }
        case 'SET_AGE_VERIFICATION': {
            let user = cookies.get("privateScreeningUser");
            const data = {
                user: user?.user?._id,
                filmId: state?.core?.screen?._id,
                filmType: 'PrivateScreeningPartnerScreen',
            }
            Service.sendevent('userScreening|getAgeVerification', data)
            return {...state}
        }
        case 'ALL_SPONSORS':
            return {...state, allSponsors: action.payload}
        case 'SET_AUDIO':
            return {...state, audio: action.payload}
        case 'PLAYER_TYPE':
            return {...state, videoPlayerType: action.payload}
        case 'SET_STATUS':
            return {...state, status: action.payload}
        case 'SET_MORE_TIME_FLAG':
            return {...state, isMoreTime: action.payload}
        case 'SET_MOVIE_STATUS':
            return {...state, movieStatus: action.payload}
        case 'SET_WAITING_ROOM_RUNNING':
            return {...state, isWaitingRoomRunning: action.payload}
        case 'SET_FIVE_HOUR_WINDOW_STATUS':
            return {...state, fiveHours: action.payload}
        case REHYDRATE:
            return {
                // ...state,
                core: action.payload ? action.payload.core : {filmType: 'PrivateScreeningPartnerScreen', film: '', date: '', channelId: '', screen:''},
                status: action.payload ? action.payload.status  : 'Coming Soon',
                // age: action.payload ? action.payload.age  : '',
                showQnA: action.payload ? action.payload.showQnA  : false,
                movieStatus: action.payload ? action.payload.movieStatus  : '',
                isMoreTime: action.payload ? action.payload.isMoreTime  : false,
                channelId : action.payload ? action.payload.channelId : '',
                messages : [],
                fiveHours: '',
                questions : []
            }
        default:
            return {...state,};
    }
}

export const reducer = sundanceReducer;
