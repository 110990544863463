import React, { Component } from 'react';

// Components
import VerifyAge from '../components/VerifyAge';
import AudioPopup from '../components/AudioPopup'; 
import ShortPopup from '../components/ShortPopup';

class Popups extends Component {
    constructor() {
        super();
		this.state = {
            verify_age: false,
            audio_popup: false,
            short_popup: false
        };		
    }

    verifyAgePopup = () => {
        this.setState({
            verify_age: true
        });
    }

    audioPopup = () => {
        this.setState({
            audio_popup: true
        });
    }

    openShortPopup = () => {
        this.setState({
            short_popup: true
        });
    }

    render() {
        // state
        let { verify_age, audio_popup, short_popup } = this.state;

        return (
            <>

                <div style={{ 'padding' : '40px'}}>
                    <button onClick={this.verifyAgePopup.bind(this)}>verify age popup</button>
                    <br />
                    <button onClick={this.audioPopup.bind(this)}>audio popup</button>
                    <br />
                    <button onClick={this.openShortPopup.bind(this)}>popup</button>
                </div>

                {/* Verify Age Popup */} 
                {
                    verify_age ? 
                        <VerifyAge closePopup={(e) => {this.setState({ verify_age: false })} } />
                    : ''
                }

                {/* Audio Popup */} 
                {
                    audio_popup ? 
                        <AudioPopup closePopup={(e) => {this.setState({ audio_popup: false })} } />
                    : ''
                }

                {/* Short Popup */} 
                {
                    short_popup ? 
                        <ShortPopup closePopup={(e) => {this.setState({ short_popup: false })} } />
                    : ''
                }
            </>
        )
    }
}
export default Popups;