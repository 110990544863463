import React from 'react';
import Linkify from 'react-linkify';
import { URL } from "../common/constant";
// import Cookies from 'universal-cookie';
const Message = React.memo((props) => {
    const ValidateLink = (TEXT) => {
        return TEXT?.replace(/(?:https?|ftp):\/\/[\n\S]+/g, '')
    }
    return (
        <div className={`chat_message ${props.isModerator ? 'moderator_chat_message' : ''}`} id={props.id}>
            <div className="chat_user_img">
                <img src={props.image && props.image.length !== 0 ? URL.IMAGE + props.image : '/images/placeholder.png'} alt="user" />
            </div>
            <div className="chat_user_data">
                <p><strong>{props.senderName}:</strong> {props.isModerator ? <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (<a target="blank" href={decoratedHref} key={key}> {decoratedText} </a>)} >{props.message}</Linkify> : ValidateLink(props.message)} </p>
            </div>
        </div>
    );
})

export default Message;