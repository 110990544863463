import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import service from './common/service';

import SignIn from '../src/pages/SignIn';
import ResetPasword from '../src/pages/ResetPasword';
import Register from '../src/pages/Register';
import ProgramDetail from './pages/ProgramDetail';
// Slick Slider
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LiveQa from './pages/LiveQa';
import MyScreening from '../src/pages/MyScreening';
import Popups from './pages/Popups';
import Cookies from 'universal-cookie';
import PrivateRoutes from './privateRoutes'
import NotFound from "./pages/not-found";
import { logout } from "./common/functions";
// import SetToken from "./pages/SetToken";

export const AuthContext = React.createContext({});
const cookies = new Cookies();

class Routers extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isSocketConnected: false,
			isAuthenticated: false
		};
		this.apiserve = new service();
	}
	componentWillMount = () => {
		let particularUser = cookies.get("privateScreeningUser")
		if (particularUser && particularUser.token) {
			this.setState({ isAuthenticated: true })
		} else {
			this.setState({ isAuthenticated: false })
		}

		this.apiserve.connect((flag) => {
			if (flag) {
				this.setState({ isSocketConnected: true })

				let particularUser = cookies.get("privateScreeningUser")
				if (particularUser && particularUser.token) {
					this.apiserve.sendevent("authentication", {
						token: particularUser.token
					});
				}
				else {
					localStorage.clear();
				}
			}

		});
	}

	componentDidMount() {

		// if (sessionStorage.getItem('reAuth')) {
			sessionStorage.clear()
		//   }
		let loginData 
		this.apiserve.getevent().subscribe((response) => {
			switch (response.event) {
				case "authentication":
					if (!response.error) {
						if(response.data.user.isDeleted){
							this.setState({isLoading: false }, () => {});
							if(!localStorage.getItem('isForceLogout')){
							  logout()
							}
						} else {
							if(cookies.get('forcelogoutkey')){
								let oldKey = cookies.get('forcelogoutkey')
								let newKey = response.data.user.forceLogoutKey
								if(oldKey !== newKey){
									logout()
								}
							} else {
								this.setState({
									isAuthenticated: true,
									isLoading: false
								})
								if (cookies.get("SundanceNewUser") || cookies.get('particularNewUser') || cookies.get('myLoginToken') ) {
									cookies.remove("SundanceNewUser", {domain: process.env.REACT_APP_COOKIE_DOMAIN, path: "/",});
									cookies.remove("particularNewUser", {domain: process.env.REACT_APP_COOKIE_DOMAIN, path: "/",});
									cookies.remove("myLoginToken", {domain: process.env.REACT_APP_COOKIE_DOMAIN, path: "/",});
									cookies.remove("STYXKEY_sundanceUser", {domain: process.env.REACT_APP_COOKIE_DOMAIN, path: "/",});
									cookies.remove("forcelogoutkey", {domain: process.env.REACT_APP_COOKIE_DOMAIN, path: "/",});
								}
								loginData={...response.data}
								cookies.set("privateScreeningUser", response.data, { domain: process.env.REACT_APP_COOKIE_DOMAIN, path: "/" });
								cookies.set("SundanceNewUser", response.data, { domain: process.env.REACT_APP_COOKIE_DOMAIN, path: "/" });
								cookies.set("myLoginToken", response.data.token, { domain: process.env.REACT_APP_COOKIE_DOMAIN, path: "/" });
								cookies.set("forcelogoutkey", response.data.user.forceLogoutKey, { domain: process.env.REACT_APP_COOKIE_DOMAIN, path: "/", })
								this.apiserve.sendevent("privateScreeningPartner|front|getProfile", { userId: response.data && response.data.user && response.data.user._id });
							}
						}
					}
					break;
				case "privateScreeningPartner|front|getProfile":
					if (!response.error) {
						let userInfo = {
							fname: response.data.fname,
							lname: response.data.lname,
							image: response.data.image,
							showMyLibrary: response.data.showMyLibrary
						};
						cookies.set("SundanceUserProfile", userInfo, { domain: process.env.REACT_APP_COOKIE_DOMAIN, path: "/" });
						// setTimeout(() => {
						// 	this.props.history.push("/myscreening");
						// }, 1000);
					}
					break;
				case "forceLogout":
					if(!response.error){
						localStorage.setItem('isForceLogout',true)
						if(response.data.is_deleted){
							window.open(process.env.REACT_APP_HOME_URL + "logout", "_self");
						} else{
							logout()
						}
					}
					break;
				default:
					break;
			}
		})
	}

	render() {
		return (
			<>
				<AuthContext.Provider
					value={{
						isAuthenticated: this.state.isAuthenticated,
						isLoading: this.state.isLoading,
						isRoute: this.state.ro,
					}}
				>
					{
						this.state.isSocketConnected ?
							<Switch>
								<Route exact path="/" component={SignIn} />
								<Route exact path="/sign-in" component={SignIn} />
								<Route exact path="/register" component={Register} />
								<Route exact path="/reset-password" component={ResetPasword} />
								<Route exact path="/register/:type?/:refCode?" component={Register} />
								<Route exact path="/register/me/:type?/:refCode?" component={Register} />
								<Route exact path="/register/:referralCode?/:screenKey?/:screenId?" component={Register} />
								<Route exact path="/sign-in/:referralCode?/:screenKey?/:screenId?" component={SignIn} />
								<Route exact path="/popups" component={Popups} />
								{/* <PrivateRoutes exact path="/program-detail/:id" component={ProgramDetail} /> */}
								{/* <PrivateRoutes exact path="/program-detail/:id/:qa" component={ProgramDetail} /> */}
								<Route exact path="/program-detail/:id" render={props => <ProgramDetail key={props.match.params.id} {...props} />} />
								<Route exact path="/program-detail/:id/:qa" render={props => <ProgramDetail qa={true} key={props.match.params.qa}  {...props} />} />
								<PrivateRoutes exact path="/live-qa" component={LiveQa} />
								<PrivateRoutes exact path="/myscreening" component={MyScreening} />
								<Route exact path="*" component={NotFound} />
							</Switch> : ''}
				</AuthContext.Provider>



			</>
		);
	}
}

export default Routers;
