export const URL = {
    IMAGE: process.env.REACT_APP_IMAGE_URL,
    SOCKET: process.env.REACT_APP_SOCKET_API_KEY,
    API: process.env.REACT_APP_API_KEY,
}
export const FILE = {
    IMAGES: /\.(svg|png|apng|jpeg|jpg|ico|hdr|bmp|webp|gif|tiff|tif|jp2|rgb|dds|cur|tga|heic|jfif|pgm|jpe|xpm|exr|map|ppm|g3|wbmp|jbg|pcx|pdb|avif|jps|rgba|heif|fax|xbm|yuv|pcd|pict|xwd|pal|vips|pgx|pbm|jbig|picon|ras|pct|fts|hrz|ipl|mng|mtv|otb|palm|pam|pfm|pnm|rgbo|sgi|sun|uyvy|viff|xv|g4|rgf|six|sixel|jif|jfi)$/i,
    VIDEO: /\.(avi|wmv|mpeg|webm|mov|mpg|divx|mpeg-2|3gp|flv|swf|m4v|ogv|mkv|mjpeg|vob|xvid|mts|av1|ts|avchd|hevc|asf|mxf|m2v|3g2|rmvb|m2ts|wtv|f4v|rm|mp4)$/i,
    AUDIO: /\.(mp3|wav|ogg|wma|m4a|flac|mp2|aac|aiff|m4r|amr|ac3|opus|gsm|wve|w64|caf|fssd|vox|dts|amb|txw|au|spx|wv|voc|tta|ra|oga|pvf|prc|maud|8svx|snd|sndr|sndt|avr|cdda|cvs|cvsd|cvu|dvms|vms|fap|paf|sou|gsrt|hcom|htk|ima|ircam|sln|sph|nist|smp|sd2)$/i,
};

export const CONSTANTS = {
    MOVIE_END_TIME: 5,
    PREMIERE_TIME: 15,
    PAGINATION_LIMIT: 20,
    CHAT_PAGINATION_LIMIT: 50,
    JOIN_EXPIRES: 240,
    WAITING_ROOM_BUFFER: 5,
    MAX_LENGTH: 50,
};
export const FILM_TYPE = {
    1: "Premiere",
    2: "Second Screening",
    3: "Award winner",
    4: "On demand",
  };