import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import Loader from './components/loader';
import { AuthContext } from './routes';

const PrivateRoutes = ({ component: Component, ...otherProps }) => {
  const { isAuthenticated, isLoading } = useContext(AuthContext)
    return (
        <Route
            {...otherProps}
            render={props => (
                !isLoading
                    ?
                    (
                        isAuthenticated
                            ?
                            <Component {...props} key={Component.key}/>
                            :
                            <Redirect to={otherProps.redirectTo ? otherProps.redirectTo : '/'} />
                    )
                    :
                    <Loader />
            )}
        />
    )

}

PrivateRoutes.propTypes = {
    component: PropTypes.func.isRequired
}

export default PrivateRoutes