import React, { Component } from 'react';
// import { Link } from "react-router-dom";

// Layout
import Header from '../layout/Header';
import Footer from '../layout/Footer';

// Components
import TextField from "../components/TextField";
import PwdField from "../components/PwdField";
import CheckBox from "../components/CheckBox";

// Icons
import ArrowRightIcon from "../images/arrowRightIcon";
import SimpleReactValidator from "simple-react-validator";
import service from "../common/service";
import Popup from '../components/Popup';
import Cookies from "universal-cookie";

const cookies = new Cookies();
let sub;
class Register extends Component {
  constructor(props) {
    super(props);

    this.state = {
      refCode: '',
      user: {
        fname: "",
        lname: "",
        email: "",
        password: "",
        confirmPassword: "",
        accept: false,
        errorMsg:[],
        popup:false,
        isNameInput:false,
        popupExits:false
      },
    };
    this.apiserve = new service();
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
  }

  componentWillUnmount = () => {
    sub.unsubscribe();
  };

  componentDidMount() {

    const refCode = this.props.match.params.type;
    const screenId = this.props.match.params.refCode;

    const referralCode= this.props.match.params.referralCode;
    const screenKey = this.props.match.params.screenKey;
    const screenIds = this.props.match.params.screenId;
    if(referralCode && screenKey && screenIds && referralCode !== 'me')
       this.setState({referralCode,screenKey, screenIds, isNameInput:true })

    if(screenId){
      this.setState({screenId})
    }
    if (refCode) {
      this.apiserve.sendevent("privateScreeningPartner|cms|getEmail", { referralCode: refCode });
      this.setState({ refCode: refCode })
    }

    sub = this.apiserve.getevent().subscribe((response) => {
      switch (response.event) {
        case "privateScreeningPartner|front|signUp":
          if (!response.error) {
            this.apiserve.sendevent("authentication", { token: response.data.token }); 
          }else{
            if(response.code === '412') {
               this.setState({popupExits: true, errorMsg: 'This email is already registered as a Sundance Film Festival user. To proceed, please click the Sign In below.'})
            }else{
               this.setState({popup:true, errorMsg:[response.data.error]})
            }
          }
          break;
        case "user|thirdPartyToken":
          if (!response.error) {
            let auth = new URL(response.data.URL);
            let token = auth.searchParams.get("auth");
            token = decodeURIComponent(token)
            window.Eventive.loginSundance({
              userToken:token
            })
            .then(order => {
              console.log(order,"oreeee",);
              if (!order) {
                return;
              }
              this.apiserve.sendevent("authentication", {
                token: cookies.get("myLoginToken"),
              });
              cookies.set("myEventiveLoginToken", order.token, {domain: process.env.REACT_APP_COOKIE_DOMAIN, path: "/" });
              this.apiserve.sendevent("user|updateEventiveDeatils", {email:order.details.email,id:order.id});
            })
            .catch(err => {
              console.log(err,"Errr");
            });
          } else {
            this.setState({
              // closeOkModal: true,
              closeMsg: response.data.error,
            });
          }
          break;
        case "privateScreeningPartner|front|register":
          if (!response.error) { 
            cookies.set("myLoginToken", response.data.token, {domain: process.env.REACT_APP_COOKIE_DOMAIN, path: "/" });
            this.apiserve.sendevent("user|thirdPartyToken", {
              id: response.data.user._id,
              redirectLink: 'https://api.eventive.org/auth/sundance/callback',
            });
            // this.apiserve.sendevent("authentication", { token: response.data.token }); 
          }else{ 
            if(response.code === '412') {
               this.setState({popupExits: true, errorMsg: 'This email is already registered as a Sundance Film Festival user. To proceed, please click the Sign In below.'})
            }else{
               this.setState({popup:true, errorMsg:[response.data.error]})
            }
          }
          break;
        case "privateScreeningPartner|privateScreeningUser|invitationLinkCreate":
          if (!response.error) { 
            cookies.set("myLoginToken", response.data.token, {domain: process.env.REACT_APP_COOKIE_DOMAIN, path: "/" });
            this.apiserve.sendevent("user|thirdPartyToken", {
              id: response.data.user._id,
              redirectLink: 'https://api.eventive.org/auth/sundance/callback',
            });
            // this.apiserve.sendevent("authentication", { token: response.data.token }); 
          } else{ 
            if(response.code === '412') {
               this.setState({popupExits: true, errorMsg: 'This email is already registered as a Sundance Film Festival user. To proceed, please click the Sign In below.'})
            }else{
               this.setState({popup:true, errorMsg:[response.data.error]})
            }
          }
          break;
        case "privateScreeningPartner|cms|getEmail":
          if (!response.error) {
            const User = response.data;
            this.setState({
              user: {
                ...this.state.user,
                email: User.email,
                fname: User.fname,
                lname: User.lname
              }
            })
          }
          break;
        case "privateScreeningPartner|front|getProfile":
          if(this.state.screenId || this.state.screenIds){
            // let id= this.state.screenId ? this.state.screenId : this.state.screenIds
            // this.props.history.push("/program-detail/" + id );
            this.props.history.push("/myscreening");
          }else{
           this.props.history.push("/myscreening");
          }
          break;
        default:
          break;  
      }
    });
  }
  closePopup = () => {
			this.setState({
				popup:false,
        errorMsg:[],
        popupExits:false
			})
  }
  reDirectSignin =() =>{
    this.setState({
      popup:false,
      errorMsg:[],
      popupExits:false
    })
    let id= this.state.screenId ? this.state.screenId : this.state.screenIds
    if(id){
       this.props.history.push('/sign-in?screenId=' + id);
    }else{
       this.props.history.push('/sign-in');
    }
  }
  handleText = (e) => {
    const { name, value } = e.target;
    this.setState({ user: { ...this.state.user, [name]: value } })
  }

  handleCheck = (e) => {
    const { name, checked } = e.target;
    this.setState({ user: { ...this.state.user, [name]: checked } })
  }

  validate = () => {
    let emailErr = ''
    let passwordErr = '';
    let passwordMustSameError = '';
    let fnameError ='';
    let lnameError = '';
    // let checkError = false;
    if (!this.state.user.password) {
      passwordErr = 'Password can not be blank'
    }
    else {
      if (!this.state.user.password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d[\]{};:=<>_+^#$@!%*?&]{8,30}$/)) {
        passwordErr = 'Password must be at least 8 characters and include one lowercase letter, one uppercase letter, and one digit.';
      }
    }
    // if (!this.state.user.accept) {
    //   checkError = 'You must agree to our community agreement in order to create an account.'
    // }
    if (!this.state.user.confirmPassword) {
      passwordMustSameError = 'Confirm Password can not be blank.'
    } else {
      if (this.state.user.password !== this.state.user.confirmPassword) {
        passwordMustSameError = 'Password and Confirm Password do not match.'
      }
    }

    if (!this.state.user.email) {
      emailErr = 'Email field is required.'
    } else {
      // eslint-disable-next-line 
      if (!this.state.user.email.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
        emailErr = 'Email address must be valid email.';
      }
    }

    if(this.state.isNameInput){
      if(!this.state.user.fname){
        fnameError = 'First Name can not be blank.'
      }else{
        fnameError ='';
      }
      if(!this.state.user.lname){
        lnameError = 'Last Name can not be blank.'
      }else{
        lnameError = '';
      }
    }else{
        fnameError ='';
        lnameError = '';
    }
    this.setState({
      passwordErr, emailErr, passwordMustSameError, fnameError, lnameError
    })
    if (passwordErr || emailErr || passwordMustSameError || fnameError || lnameError) {
      return false
    }
    return true
  }

  submit = (e) => {

    const { user ,referralCode, screenKey, screenIds} = this.state;

    e.preventDefault();
    var data = {
      email: user.email,
      password: user.password,
      fname: user.fname,
      lname: user.lname,
      receiveMarketingCommunication: user.accept,
    };
    
    var reg = {
      referralCode: this.state.refCode,
      password: user.password
    }
    var isVAlid = this.validate();
    if (isVAlid) {
      if (this.props.match.params.refCode) {
        this.apiserve.sendevent("privateScreeningPartner|front|register", reg);
      }
      else if(referralCode && screenKey && screenIds && referralCode !== 'me'){
          data.referralCode = referralCode;
          data.screenKey = screenKey;
          this.apiserve.sendevent("privateScreeningPartner|privateScreeningUser|invitationLinkCreate", data);
      }
      else {
        this.apiserve.sendevent("privateScreeningPartner|front|signUp", data);
      }
    }
    else {
      this.validator.showMessages();
      this.forceUpdate();
      setTimeout(() => {
        this.openError();
      }, 20);
    }
  };

  openError=()=>{
    this.setState({
      errorMsg:[this.state.emailErr, this.state.passwordErr, this.state.passwordMustSameError, this.state.fnameError,this.state.lnameError,this.state.checkError]
    },()=>{this.setState({popup:true})})
  }

  gotoSignIn=()=>{
    if(this.state.screenIds || this.state.screenId){
      this.props.history.push('/sign-in?screenId=' + this.state.screenIds || this.state.screenId)
    }else{
      this.props.history.push('/sign-in')
    }
  }

  render() {
    const { user, refCode, errorMsg ,popup , popupExits} = this.state;
    return (
      <>
        <Header isMenuShow={true} history={this.props.history}/>

        <div className="home register">
          <section className="sd_user_form_section sd_create_form_section">
            <div className="sd_inner_container">

              {/* <div className="banner_display_contant">
                <h2 className="sd_textuppercase">Sundance Institute presents</h2>
                <h1 className="sd_textuppercase">Sundance Film Festival 2021</h1>
              </div> */}

              <div className="sd_user_form sd_create_form">
                <div className="sd_user_form_header sd_create_form_header">
                  <h3 className="sd_textuppercase">Create Account</h3>
                </div>

                <form onSubmit={this.submit} className="sd_signin_form">
                  <div className="sd_form_group_half">
                    <div className="sd_form_group">
                      <TextField name='fname' disable={refCode} onChange={this.handleText} value={user.fname} label="First Name" require="require" type="text" placeholder="" />
                      {this.state.fnameError ? <span style={{ color: 'white' }} className='srv-validation-message'>{this.state.fnameError}</span> : ''}
                    </div>
                    <div className="sd_form_group">
                      <TextField name='lname' disable={refCode} onChange={this.handleText} value={user.lname} label="Last Name" require="require" type="text" placeholder="" />
                      {this.state.lnameError ? <span style={{ color: 'white' }} className='srv-validation-message'>{this.state.fnameError}</span> : ''}
                    </div>
                  </div>

                  <div className="sd_form_group">
                    <TextField name='email' disable={refCode} onChange={this.handleText} value={user.email} label="Email" type="text" require="require" />
                    {this.state.emailErr ? <span style={{ color: 'white' }} className='srv-validation-message'>{this.state.emailErr}</span> : ''}
                  </div>

                  <div className="sd_form_group sd_form_pwd_group">
                    <PwdField name="password" onChange={this.handleText} label="Password" value={user.password} type="password" require="require" />
                    {!this.state.passwordErr ? '' :
                      <p className="suggession_text">Password must be at least 8 characters and include one lowercase letter, one uppercase letter, and one digit.</p>
                    }
                  </div>

                  <div className="sd_form_group sd_form_pwd_group">
                    <PwdField name="confirmPassword" label="Confirm Password" onChange={this.handleText} value={user.confirmPassword} type="password" require="require" />
                    {this.state.passwordMustSameError ? <span style={{ color: 'white' }} className='srv-validation-message'>{this.state.passwordMustSameError}</span> : ''}
                  </div>

                  <div className="sd_form_checkbox_group">
                    <CheckBox
                      type="checkbox"
                      checked={user.accept}
                      id="accept"
                      name="accept"
                      onChange={this.handleCheck}
                      label="Yes, I would like to receive email communications from Sundance Institute."
                    />
                    {this.state.checkError ? <span style={{ color: 'red' }} className='srv-validation-message'>{this.state.checkError}</span> : ''}
                  </div>

                  <div className="sd_form_submit">
                    <input className="sd_btn sd_btn_black" type="submit" value="Submit" onClick={(e) => this.submit(e)} />

                    <p className="sd_form_submit_note">
                      By clicking “Submit” you agree to our
                                              <a href='https://www.sundance.org/about/privacy-policy' target="blank"> Terms of Use and Privacy Policy </a>
                    </p>
                  </div>

                  <div className="sd_Create_an_Account">
                    <i>or</i>
                    <span onClick={()=>this.gotoSignIn()} className="create_link_btn pointer"> Sign in <ArrowRightIcon /> </span>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </div>
        {
				popup && errorMsg ? 
					<Popup
						title=''
						popup_note={errorMsg}
						form={false}
						onChildClick={this.closePopup}
					/>
				: ''
			}

      {
        popupExits && errorMsg ?
        <AlredyExist 	title='User Already Exists!' note={errorMsg} closePopup={this.closePopup} reDirectSignin={this.reDirectSignin}/> : ''
      }
        <Footer />
      </>
    )
  }
}

export default Register;

const AlredyExist = ({ title, note,closePopup,reDirectSignin} ) => {
  return (
      <div className="sd_popup">
          <div className="sd_popup_inner">
              {
                  title ?
                      <div className="sd_popup_header text_center">
                          <h3>{title}</h3>
                      </div>
                      :
                      ''
              }
              <div className="sd_popup_content">
                  <p>{note}</p>
                  <div className="sd_popup_form sd_user_form">
                      <div className="sd_form_buttons sd_popup_buttons">
                          <button className="sd_form_submit_button sd_grey_btn" type="button" onClick={closePopup} >
                              Cancel
                          </button>
                          <button className="sd_form_submit_button sd_yellow_fill_btn" type="button" onClick={reDirectSignin}> Sign In </button>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  )
}