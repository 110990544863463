import React, { useEffect, useState} from 'react';
import moment from "moment";
// import {CONSTANTS} from "../common/constant";
import * as workerTimers from 'worker-timers';

let interval;
// eslint-disable-next-line
const Format = 'DD-MM-YYYY HH:mm:ss'

const MovieStatus = (props) => {
    const [now, setNow] = useState(moment(props.filmDate))

    useEffect(() => {
        const showTime = moment(props.endDate)
            if (now.isAfter(showTime)) {
            if (interval) {
                workerTimers.clearInterval(interval);
            }
           // props.showPopup(true);
        }
        // eslint-disable-next-line
    }, [now])

    useEffect(() => {
        interval = workerTimers.setInterval(() => setNow(moment()), 1000)
    }, [])

    return <React.Fragment/>
}

export default MovieStatus;