import React from 'react';
import {Link, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import service from "../common/service";

const AudioPopup = React.memo((props) => {
    const {date} = useParams()
    const dispatch = useDispatch()
    const _FILM = useSelector(e => e.film)
    const _AGE = useSelector(e => e.age)
    const _AUDIO = useSelector(e => e.audio) || false
    const FIVE_HR = useSelector(e =>e.fiveHours)

    const playMovie = flag => {
        dispatch({type: 'SET_AUDIO', payload: flag})
        if (_AUDIO !== flag) {
            if ( FIVE_HR === 'Started'  && _AGE === 'verified') service.sendEvent('digitalscreening|getShift72Token', {filmId: _FILM._id, dateTimeId: date, audio: flag});
            else if (_AGE !== 'verified' && _AGE !== '') dispatch({type: 'AGE_VERIFICATION', payload: 'checking'})
            else if ( FIVE_HR !== 'Started'  && _AGE === 'verified') {
                dispatch({type:'SET_FIVE_HOUR_WINDOW_STATUS', payload: 'Waiting'})}
            props.closePopup()
        }
    }
    return (
        <div className="sd_popup sd_custom_popup sd_audio_desc_popup">

            <div className="sd_popup_inner">
                {/* Close button */}
                <button className="sd_close_popup" onClick={props.closePopup}>
                    <span></span>
                    <span></span>
                </button>

                <div className="sd_popup_header">
                    <h2 className="sd_textuppercase">Watch with Audio Description?</h2>
                </div>

                <div className="sd_popup_header_desc">

                    <div className="sd_audio_desc_block">
                        <div className="sd_audio_desc_block_img">
                            <img src="/images/audio_desc_icon.svg" alt="Watch with Audio Description" title="Watch with Audio Description"/>
                        </div>

                        <Link onClick={() => playMovie(true)} className="sd_link_btn sd_link_btn_pink sd_justcenter">
                            Watch with Audio Description
                            <img src="/images/slider_arrow_right_pink.svg" alt="Watch with Audio Description"
                                 title="Watch with Audio Description"/>
                        </Link>
                    </div>

                    <div className="sd_audio_desc_block">
                        <div className="sd_audio_desc_block_img">
                            <img src="/images/no_audio_desc_icon.svg" alt="Continue without Audio Description"
                                 title="Continue without Audio Description"/>
                        </div>

                        <Link onClick={() => playMovie(false)} className="sd_link_btn sd_link_btn_pink sd_justcenter">
                            Continue without Audio Description
                            <img src="/images/slider_arrow_right_pink.svg" alt="Continue without Audio Description"
                                 title="Continue without Audio Description"/>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default AudioPopup;