import React, {Component} from 'react';

let interval;

class Timer extends Component {

    componentDidMount() {
        interval = setInterval(() => this.props.updateTime(), 1000)
    }
    componentWillUnmount() {
        clearInterval(interval)
    }

    secondsToTime = (secs) => {
        const h = Math.floor(secs / (60 * 60));

        const divisor_for_minutes = secs % (60 * 60);
        const m = Math.floor(divisor_for_minutes / 60);

        const divisor_for_seconds = divisor_for_minutes % 60;
        const s = Math.ceil(divisor_for_seconds);

        return {
            "h": h,
            "m": m,
            "s": s
        };
    }
    render() {
        const {time,SortTime} = this.props;
        return (
            <h2 className=""> {SortTime ? `Starts in ${this.secondsToTime(time).m}:${this.secondsToTime(time).s.toString().length === 1 ? '0' + this.secondsToTime(time).s : this.secondsToTime(time).s}` :`${this.secondsToTime(time).m} Minutes, ${this.secondsToTime(time).s} seconds`}  </h2>

            // <h2> Starts in {this.secondsToTime(time).m}: {this.secondsToTime(time).s.toString().length === 1 ? '0' + this.secondsToTime(time).s : this.secondsToTime(time).s} </h2>
        );
    }
}

export default Timer;