import React, {Component} from 'react'


//Loader 
import LoaderSvg from "../images/loader.svg";

class Loader extends Component {
    render(){
        return(
            <div id="pre-loader" style={{ display: "flex" }}>
                <img src={LoaderSvg} alt='' />
            </div>
        )
    }
}

export default Loader

