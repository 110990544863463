import React, { useState, useEffect } from 'react'
import service from '../common/service'
// Components
import TextField from "../components/TextField";
let sub;

function Popup({ title, popup_note, form, onChildClick }) {
    const apiServe = new service()
    const [email, setEmail] = useState('')
    const [emailErr, setEmailErr] = useState('')
    const [isError, setIsError] = useState('')
    useEffect(() => {
        eventCall();
        return () => {
            sub.unsubscribe()
        }
    })

    // Popup Close
    function closePopup(data) {
        onChildClick(data);
    }

    function forgetPwd() {
        setEmailErr('');
        let counter = 0;
        if (!email) {
            setEmailErr('The email field is required.')
            counter++;
        } else {
            // eslint-disable-next-line
            if (!email.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                setEmailErr('Email address must be valid email.');
                counter++;
            }
        }
        if (emailErr || counter > 0) {
            return false
        }
        else {
            apiServe.sendevent("privateScreeningPartner|front|forgetPassword", { email: email })
        }
    }

    const eventCall = () => {

        sub = apiServe.getevent().subscribe((response) => {
            switch (response.event) {
                case "privateScreeningPartner|front|forgetPassword":
                    if (!response.error) {
                        closePopup(response.error)
                        setIsError("Reset email has been sent, please check your email.")
                    } else {
                        setIsError(response.data.error)
                    }
                    break;
                default:
                    break
            }
        })
    }

    const onInputChange = (e) => {
        const { value } = e.target
        if (value && value.length > 0) {
            setEmailErr('')
            setIsError('')
        }
        setEmail(value.trim());
    }

    return (
        <React.Fragment>
            <div className="sd_popup">
                <div className="sd_popup_inner">
                    {
                        title ?
                            <div className="sd_popup_header text_center">
                                <h3>{title}</h3>
                            </div>
                            :
                            ''
                    }

                    <div className="sd_popup_content">
                        {Object.values(popup_note).map((note) => {
                            return <p>{note}</p>;
                        })}

                        {
                            form ?
                                <div className="sd_popup_form sd_user_form">
                                    {
                                        title === "Forgot Password" ?
                                            <form className="sd_forgot_password">
                                                <div className="sd_form_group">
                                                    <TextField type="text" label="email" name="email" value={email} onChange={(e) => onInputChange(e)} />
                                                    {
                                                        emailErr ? <span className="error text_center">{emailErr}</span> : ''
                                                    }
                                                    {
                                                        isError ? <span className="error text_center">{isError}</span> : ''
                                                    }
                                                </div>
                                                <div className="sd_form_buttons sd_popup_buttons">
                                                    <button className="sd_form_submit_button sd_grey_btn" type="button" onClick={closePopup} >
                                                        Cancel
                                                    </button>
                                                    <button className="sd_form_submit_button sd_yellow_fill_btn" type="button" onClick={forgetPwd}> Submit </button>
                                                </div>
                                            </form>
                                            :
                                            ''
                                    }
                                </div>
                                :
                                <div className="sd_popup_buttons sd_popup_confirm_buttons">
                                    <button className="sd_grey_btn" type="button" onClick={closePopup} >
                                        OK
                                    </button>
                                </div>
                        }
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Popup;