import React, { Component } from 'react'

// Layout
import Footer from '../layout/Footer';
import Header from '../layout/Header';

// Components
import ProgramDetails from '../components/ProgramDetails';
import Chat from '../components/Chat';
import HeroHeader from '../components/HeroHeader';
import FilmVideo from '../components/FilmVideo';
import Cookies from 'universal-cookie';
import service from '../common/service';
import Popup from '../components/Popup';
import BroughtBy from '../components/BroughtBy';
import moment from 'moment'
import { connect } from "react-redux";
import VerifyAge from '../components/VerifyAge';
import QNA from '../components/q-n-a'
import { CONSTANTS } from '../common/constant';
import FourHourPopup from '../components/FourHourPopup';
import WatchEndPopup from '../components/WatchEndPopup';


const cookies = new Cookies();
const mapStateToProps = state => ({
    _STATUS: state.status,
    _AGE: state.age,
    FIVE_HR: state.fiveHours,
    _MOVIE_STATUS: state.movieStatus,
    _MOVIE_EVENTIVE:state.movieEventiveId,
    _MY_TICKETID:state.myTicketId,
    _C: state.core,
    _C_S: state.chatStatus,
    _QA: state.showQnA,
    _Q_S: state.questionStatus,
    _IS_MORE_TIME: state.isMoreTime,
    _SP: state.showPlayer,
    _AUDIO : state.audio || false,
    VIDEO_TYPE: state.videoPlayerType,
    _FIVE_HR_COMPLETE: state.fiveHoursComplete
});

let sub;
let notShowing = true;
class ProgramDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentTime: '',
            endvideo: '',
            duration: '',
            films_tab: [
                { title: 'program details', title_name: 'program_details', isActive: true },
                { title: 'about the film', title_name: 'about_the_film', isActive: false },
                { title: 'more info', title_name: 'more_info', isActive: false }
            ],
            films_chat: [
                { title: 'Chat', title_name: 'Chat', isActive: true },
                { title: 'Live Q&A', title_name: 'Live_Q&A', isActive: false },
            ],
            active_film_tab: 'Chat',
            screenDetais: {},
            errorMsg: '',
            isError: false,
            token: '',
            movieToken: '',
            shiftId: '',
            progress: 0,
            remainingTime: 0,
            isShowPlayer: true,
            isFiveHourEnd: false
        };
        this.apiserve = new service();
    }
    componentWillUnmount = () => {
        sub.unsubscribe();
    };

    componentDidMount() {
        window.scroll(0, 0);
        let user = cookies.get("privateScreeningUser");
        if (!user) {
            this.props.history.push('/sign-in')
        }
        if (this.props.match && this.props.match.params.id) {
            this.setState({ screenId: this.props.match.params.id }, () => {
                this.getbyId()
                this.forceUpdate();
                if (this.props.match && this.props.match.params.qa) {
                    this.watchQA();
                }
            })
        }

        if (!notShowing && this.props.FIVE_HR === 'Waiting' && this.props._AGE === 'verified') {
            /**
             * If movie is with explicit content and user's age is verified then show movie
             * */
            notShowing = true
            this.startMovie()
        } else if (this.props.FIVE_HR === 'Waiting' || this.props._AGE === 'checking') notShowing = false
        sub = this.apiserve.getevent().subscribe((response) => {
            switch (response && response.event) {
                case "privateScreeningPartner|front|getScreenById":
                    if (!response.error) {
                        this.props.dispatch({ type: 'SAVE_CORE_DATA', payload: { filmType: 'PrivateScreeningPartnerScreen', film: response.data?.filmId?._Id, date: response.data.screeningDate, channelId: '', screen: response.data } })
                        this.props.dispatch({ type: 'PLAYER_TYPE', payload: response.data?.filmId?.playerType ? response.data?.filmId?.playerType : 1 })
                        this.apiserve.sendevent("userPrivateScreening|joinChannel", { screeningId: response.data._id, film: response.data?.filmId?._id });
                        this.apiserve.sendevent("userScreening|getEnvironment", {});
                        this.setState({ screenDetais: response.data })
                        // this.setState({ screenDetais: response.data, shiftId: response.data.shift72Id.toString() || '76750' })
                        this.forceUpdate();

                    } else {
                        this.setState({ isError: true, errorMsg: response.data.error })
                    }
                    break;
                case 'userScreening|getEventivePlayToken':
                // eslint-disable-next-line 
                case 'userScreening|getPlayToken': {
                    if (!response.error) {
                        const isTime = parseInt(response.data.remainingTime) + CONSTANTS.WAITING_ROOM_BUFFER || 0;
                        // if (this.props.VIDEO_TYPE === 1) {
                        //     const condition = response.data.token && response.data.shift72Id.length !== 0
                        //     // const isTime = parseInt(response.data.remainingTime) + CONSTANTS.WAITING_ROOM_BUFFER || 0;
                        //     this.setState({ token: response.data.token, shiftId: response.data.shift72Id, remainingTime: isTime || 0, progress: response?.data?.progress || 0 })
                        //     this.props.dispatch({ type: 'PLAYER_STATUS', payload: condition })
                        // } else {
                            const condition = true
                            // const condition = (response.data[isTime > 5 ? 'trailerTokenResponse' : 'movieTokenResponse'] || response.data.isSkipTokenCall) && response.data.shift72Id.length !== 0
                            this.setState({ 
                                token: response.data.isSkipTokenCall ? true : response.data['tokenResponse']?.embed_src, 
                                shiftId: response.data.shift72Id, remainingTime: isTime || 0, 
                                // progress: response?.data?.progress || 0, 
                                movieToken: response.data.isSkipTokenCall  ? '' : response.data.tokenResponse?.embed_src 
                            })
                            if (sessionStorage.getItem('currant') && sessionStorage.getItem('currant') !== response.data.shift72Id) {
                                sessionStorage.clear()
                                this.apiserve.sendevent('userScreening|getEventivePlayToken', { isMovie: true, filmId: this.props._C?.screen?.filmId?._id, screeningId: this.props._C?.screen?._id, audio: this.props._AUDIO || false , isSkipTokenCall: false ,movieEventiveId:this.props._MOVIE_EVENTIVE, myTicketId:this.props._MY_TICKETID });
                            }
                            sessionStorage.setItem('currant', response.data.shift72Id)
                            if (!response.data.isSkipTokenCall && sessionStorage.getItem(this.state.screenId) === null) {sessionStorage.setItem(this.state.screenId, response.data.tokenResponse?.embed_src); sessionStorage.setItem('currant', response.data.shift72Id)}
                            

                            this.props.dispatch({ type: 'PLAYER_STATUS', payload: condition })
                        // }
                        this.forceUpdate();
                        if (response.data.isMovie && (response.data.reloadRemaingSeconds || response.data.reloadRemaingSeconds === 0)) {
                            setTimeout(() => {
                                this.setState({ isFiveHourEnd: true })
                                this.props.dispatch({ type: 'SET_MOVIE_STATUS', payload: '' })
                                this.props.dispatch({ type: 'PLAYER_STATUS', payload: false })
                            }, (response.data.reloadRemaingSeconds * 1000));
                        }

                    } else {
                        this.setState({ isError: true, isShowPlayer: false, errorMsg: response.data.error })
                    }
                }
                    break;
                case 'userScreening|setAgeVerification':
                    if (!response.error) {
                        this.props.dispatch({ type: 'SET_AGE_VERIFICATION', payload: response.data });
                        this.setState({ isShowPlayer: (this.state.failedAge && !this.state.isShowPlayer) || this.state.isShowPlayer ? true : false })
                        this.forceUpdate();
                    } else {
                        this.setState({ isError: true, isShowPlayer: false, errorMsg: response.data.error, failedAge: true })
                    }
                    break;
                case 'userScreening|getAgeVerification':
                    if (!response.error) {
                        this.props.dispatch({ type: 'AGE_VERIFICATION', payload: response.data.IsVerified ? 'verified' : 'checking' })
                        this.forceUpdate();
                    } else {
                        this.setState({ isError: true, isShowPlayer: false, errorMsg: response.data.error })
                    }
                    break;
                case 'userPrivateScreening|joinChannel':
                    if (!response.error) {
                        this.props.dispatch({ type: 'PLAYER_TYPE', payload: response.data?.playerType ? response.data?.playerType : 1 })
                        this.apiserve.sendevent("userScreening|channelStatus", { channelId: response.data.channelId });
                        this.props.dispatch({ type: 'JOIN_CHANNEL', payload: response.data })
                        this.forceUpdate();
                    } else {
                        this.setState({ isError: true, errorMsg: response.data.error })
                    }
                    break;
                case 'admin|screenerChangeChannelStatus':
                case 'userScreening|channelStatus': {
                    this.props.dispatch({ type: 'CHANNEL_STATUS', payload: response.data })
                    this.forceUpdate();
                    break;
                }
                case 'userPrivateScreening|getPublishedMessage': {
                    response.data.docs = response.data.docs.reverse()
                    this.props.dispatch({ type: 'PUBLISHED_MESSAGES', payload: response.data })
                    break;
                }
                case 'admin|screenerPublishMessage':
                case 'userScreening|publishMessage': {
                    this.props.dispatch({ type: 'PUBLISH_MESSAGE', payload: response.data })
                    break;
                }
                case 'admin|screenerDeleteMessage': {
                    this.props.dispatch({ type: 'DELETE_MESSAGE', payload: response.data })
                    break;
                }
                case 'admin|screenerDeleteQuestion': {
                    this.props.dispatch({ type: 'DELETE_QUESTION', payload: response.data })
                    break;
                }
                case 'userScreening|updateQuestionLike': {
                    this.props.dispatch({ type: 'QUESTION_LIKE', payload: response.data })
                    break;
                }
                case 'userScreening|reload': {
                    // this.setState({isFiveHourEnd:true})
                    // this.props.dispatch({ type: 'SET_MOVIE_STATUS', payload: '' })
                    // this.props.dispatch({ type: 'PLAYER_STATUS', payload: false })
                    // this.forceUpdate()
                    // window.open(process.env.REACT_APP_HOME_URL + 'my-account?tab=2', '_self')
                    // window.location.reload()
                    break;
                }
                case 'userScreening|getPublishedQuestion': {
                    response.data.docs = response.data.docs.reverse()
                    this.props.dispatch({ type: 'PUBLISHED_QUESTIONS', payload: response.data })
                    break;
                }
                case 'privateScreening|stopPlayer': {
                    // eslint-disable-next-line 
                    if (response.data && !response.data.error || response.code === 200)
                        this.props.dispatch({ type: 'PLAYER_STATUS', payload: true })
                    else {
                        this.props.dispatch({ type: 'PLAYER_STATUS', payload: false })
                        this.setState({ isError: true, isShowPlayer: false, errorMsg: response.data.error })
                    }
                    break;
                }
                // eslint-disable-next-line
                case 'admin|screenerPublishMessage':
                // eslint-disable-next-line
                case 'userScreening|publishMessage': {
                    this.props.dispatch({ type: 'PUBLISH_MESSAGE', payload: response.data })
                    break;
                }
                case 'admin|screenerPublishQuestion':
                case 'userScreening|publishQuestion': {
                    this.props.dispatch({ type: 'PUBLISH_QUESTION', payload: response.data })
                    break;
                }
                case 'userPrivateScreening|setFiveHoursEnable': {
                    if (!response.error) {
                        // if (this.props.VIDEO_TYPE === 1) {
                        //     window.location.reload()
                        // }else{
                            this.props.dispatch({ type: 'PLAYER_STATUS', payload: true })
                            this.props.dispatch({ type: 'SET_FIVE_HOUR_WINDOW_STATUS', payload: 'Started'  })
                        // }
                    }
                    // this.props.dispatch({ type: 'PUBLISH_QUESTION', payload: response.data })
                    break;
                }
                default:
                    break;
            }
            this.forceUpdate();
        });
    }

    refresh = () => {
        this.setState({});
        this.forceUpdate();
    }
    watchQA = (flag) => {
        let _selfLive = cookies.get("fromTrailer");
        if (this.props._MOVIE_STATUS === 'continuous' && !this.props._IS_MORE_TIME && !flag && !_selfLive) {
            this.props.dispatch({ type: 'SET_MOVIE_STATUS', payload: '' })
        }
        else if (this.props._MOVIE_STATUS === 'continuous') { //&& _selfLive?._self
            this.props.dispatch({ type: 'SHOW_QNA', payload: true })
        } else {
            this.props.dispatch({ type: 'SHOW_QNA', payload: false })
        }
        this.props.dispatch({ type: 'SET_STATUS', payload: 'End' })
        this.refresh();
        this.props.history.push('/program-detail/' + this.state.screenId + '/q-a')
    }

    getbyId() {
        let user = cookies.get("privateScreeningUser");
        if (user && user.user)
            this.apiserve.sendevent("privateScreeningPartner|front|getScreenById", { id: this.state.screenId, userId: user.user._id });
    }
    // Switch Tabs
    // switchTab = (tab_data) => {

    //     var films_tab_copy = [...this.state.films_tab];

    //     films_tab_copy.forEach((tab) => {
    //         tab.isActive = false;
    //         if (tab.title === tab_data.title) {
    //             tab.isActive = true;
    //         }
    //         this.setState({
    //             active_film_tab: tab_data.title_name
    //         });
    //     });

    //     this.setState({
    //         films_tab: films_tab_copy
    //     });

    // }

    switchTabChat = (tab_data) => {

        var films_tab_copy = [...this.state.films_chat];

        films_tab_copy.forEach((tab) => {
            tab.isActive = false;
            if (tab.title === tab_data.title) {
                tab.isActive = true;
            }
            this.setState({
                active_film_tab: tab_data.title_name
            });
        });

        this.setState({
            films_chat: films_tab_copy
        });

    }

    closePopup = () => {
        this.setState({
            isError: false,
            errorMsg: ''
        })
    }

    render() {
        let { active_film_tab, isError, errorMsg, screenDetais, remainingTime, films_chat } = this.state;
        let user = cookies.get("SundanceUserProfile");
        let partner = screenDetais && screenDetais.filmId && screenDetais.filmId.updatedBy
        // console.log('.....', this.state.isShowPlayer, active_film_tab);
        return (
            <div>

                {/* Header */}
                <Header isMenuShow={true} />

                {/* Hero header */}

                {screenDetais?.filmId ? <HeroHeader refresh={this.refresh} watchQA={this.watchQA} title={screenDetais && screenDetais.filmId && screenDetais.filmId.title} partner={partner} {...this.props}
                    date={screenDetais && screenDetais.screeningDate ? moment(screenDetais.screeningDate).format('MMMM DD, hh:mm A') : ''} user={user} remainingTime={remainingTime > 0 ? remainingTime : 0} /> : ''}

                {/* Video */}
                <div className="sd_video_screen_section " >
                    <div className="sd_inner_container">
                        <div className="sd_video_screen_wpr_main ">
                            {
                                this.props._SP && this.state.isShowPlayer ?
                                    (this.props._C?.screen?.qaReplayLink && this.props._STATUS === 'End') || (this.state.token && this.state.shiftId && !this.props._IS_MORE_TIME) ?
                                        <FilmVideo refresh={this.refresh} watchQA={this.watchQA} videoEnd={true} token={this.state.token} movieToken={this.state.movieToken} shiftId={this.state.shiftId} progress={this.state.progress} />
                                        : ''

                                    : ''}


                            {/* <div className="sd_films_tab_sec_chat">
                                <div className="sd_films_tab_wpr_chat">
                                    <ul>
                                        {
                                            films_chat.map((tab) => {
                                                return (
                                                    <li className={`${tab.isActive ? 'active' : ''}`} key={tab.title}>
                                                        <button className={`sd_film_tab_button sd_film_tab_${tab.color}`} onClick={this.switchTab.bind(this, tab)}>
                                                            {tab.title}
                                                        </button>
                                                    </li>
                                                )
                                            })
                                        }

                                    </ul>
                                </div>
                            </div> */}

                            {/* chat */}
                            {(this.props._STATUS !== 'Now Playing' || this.props._MOVIE_STATUS === 'continuous') &&
                                <div className={((this.props._QA && this.props._STATUS !== 'Now Playing' && this.props._MOVIE_STATUS === 'continuous') || (this.props._STATUS === 'Coming Soon' && !this.props._IS_MORE_TIME)) && (this.props._STATUS !== 'Now Playing' && !this.props._IS_MORE_TIME) ? "sd_chat_section" : 'sd_chat_hide'}>
                                    {/* <div className="sd_inner_container"> */}
                                        {
                                            this.props._QA && this.props._STATUS === 'End' && this.props._MOVIE_STATUS === 'continuous' ?
                                                <div className="sd_films_tab_sec_chat">
                                                    <div className="sd_films_tab_wpr_chat">
                                                        <ul>
                                                            {
                                                                films_chat.map((tab) => {
                                                                    return (
                                                                        <li className={`${tab.isActive ? 'active' : ''}`} key={tab.title}>
                                                                            <button className={`sd_film_tab_button sd_film_tab_${tab.color}`} onClick={this.switchTabChat.bind(this, tab)}>
                                                                                {tab.title}
                                                                            </button>
                                                                        </li>
                                                                    )
                                                                })
                                                            }

                                                        </ul>
                                                    </div>
                                                    {/* {active_film_tab === 'Chat' ? <Chat />  : ''}
                                                {active_film_tab === 'Live_Q&A' ?<div className="sd_live_qa_section"> <QNA /> </div>  : ''} */}
                                                </div>
                                                : ''
                                        }

                                        {(active_film_tab === 'Chat' && ((this.props._QA && this.props._STATUS !== 'Now Playing' && this.props._MOVIE_STATUS === 'continuous') || (this.props._STATUS === 'Coming Soon' && !this.props._IS_MORE_TIME))) && (this.props._STATUS !== 'Now Playing' && !this.props._IS_MORE_TIME) ? <Chat showHeader={(this.props._QA && this.props._STATUS === 'End' && this.props._MOVIE_STATUS === 'continuous') ? false : true} /> : ''}

                                        {active_film_tab === 'Live_Q&A' && this.props._QA && this.props._STATUS === 'End' && this.props._MOVIE_STATUS === 'continuous' ? <div className="sd_live_qa_section"> <QNA />  </div> : ''}

                                        {
                                            this.props.core !== 0 && this.props._STATUS !== 'Now Playing' && this.props._STATUS !== 'End' && !this.props._IS_MORE_TIME &&
                                            <div className="sd_live_seat_section">
                                                <React.Fragment>
                                                    {/* `  {screenDetais.screeningDate && <SeatFilling date={screenDetais.screeningDate} />} */}
                                                    {/* <HashTag />` */}
                                                </React.Fragment>
                                            </div>
                                        }

                                    {/* </div> */}
                                </div>}
                        </div>
                    </div>
                </div>


                {/* Brought by */}
                {
                    (this.props._STATUS !== 'Coming Soon') ?
                        <div className="sd_outer_brought">
                            {
                                partner && partner.partnerLogo ? <div className="sd_brought_by">
                                    {/* <p>Brought to you by</p> */}
                                    <span>
                                        <img src={partner.partnerLogo && !partner.partnerLogo.includes('http') ? this.apiserve.imageUrl + partner.partnerLogo :
                                            partner.partnerLogo && partner.partnerLogo.includes('http') ? partner.partnerLogo : ''} alt="Sponsors" title="Sponsors" />
                                    </span>
                                </div> : <div className="sd_brought_by"><h3>
                                    {partner?.partnerName}
                                </h3></div>
                            }
                        </div> : ''
                }




                {/* Tabs */}
                <div className="sd_films_tab_sec">

                    {/* <div className="sd_films_tab_inner">
                        <div className="sd_inner_container">
                            <div className="sd_films_tab_wpr">
                                <ul>
                                    {
                                        films_tab.map((tab) => {
                                            return (
                                                <li className={`${tab.isActive ? 'active' : ''}`} key={tab.title}>
                                                    <button className={`sd_film_tab_button sd_film_tab_${tab.color}`} onClick={this.switchTab.bind(this, tab)}>
                                                        {tab.title}
                                                    </button>
                                                </li>
                                            )
                                        })
                                    }

                                </ul>
                            </div>
                        </div>
                    </div> */}

                    <div className="sd_films_tab_content">
                        <div className="sd_inner_container">
                            <div className="sd_films_tab_content_wpr sd_film_artists_credits">

                                <ProgramDetails film={this.state.screenDetais && this.state.screenDetais.filmId} />
                                {/* {
                                    active_film_tab === 'about_the_film' ?
                                        <AboutFilm film={this.state.screenDetais && this.state.screenDetais.filmId} />
                                        : ''
                                }

                                {
                                    active_film_tab === 'more_info' ?
                                        <MoreInfo film={this.state.screenDetais && this.state.screenDetais.filmId} />
                                        : ''
                                } */}

                            </div>

                        </div>
                        {
                            this.state.screenDetais && this.state.screenDetais.filmId && this.state.screenDetais.filmId.isExplicitContent ?
                                <div className="sd_films_content_warning">
                                    <p>WARNING: THIS FILM CONTAINS EXPLICIT CONTENT</p>
                                </div> : ''
                        }

                    </div>


                </div>
                {
                    partner && partner.partnerLogo && this.props._STATUS !== 'Coming Soon' ?
                        <BroughtBy partner={partner} /> : ''
                }

                {/* Footer */}
                <Footer />
                {/* After 5Hour popup */}
                { this.props._FIVE_HR_COMPLETE &&  <WatchEndPopup /> }
                {/* {this.state.isFiveHourEnd && <WatchEndPopup />} */}
                {console.log(this.props,"props")}
                {/* {this.props.FIVE_HR === 'Started' && this.props._AGE === 'checking' && this.props._STATUS === 'Now Playing' && <VerifyAge />} */}

                {this.props.FIVE_HR === 'Waiting' && this.props._STATUS === 'Now Playing' && <FourHourPopup />}
                {
                    isError && errorMsg ?
                        <Popup
                            title=''
                            popup_note={[errorMsg]}
                            form={false}
                            onChildClick={this.closePopup}
                        />
                        : ''
                }

            </div>
        )
    }
}

export default connect(mapStateToProps, null)(ProgramDetail);
