import React, { Component } from 'react';

class BroughtBy extends Component {
    render() {
     const {partner} = this.props;
        return (
            <div className="sd_brought_by_section">
                <div className="sd_inner_container">
                    <div className="sd_brought_by_sec">
                        <p className="sd_textuppercase">Brought to you by</p>
                        <img src={partner.partnerLogo && !partner.partnerLogo.includes('http') ? process.env.REACT_APP_IMAGE_URL + partner.partnerLogo : 
                                               partner.partnerLogo && partner.partnerLogo.includes('http') ? partner.partnerLogo : ''} alt="IMDb" title="IMDb"/>
                    </div>
                </div>
            </div>
        )
    }
}
export default BroughtBy;