import React, { Component } from 'react';

// Components
// import CreditName from '../components/CreditName';
import CreditName from './CreditName';

class AboutFilm extends Component {
    constructor(props) {
        super(props);
        this.state = {}

    }
    getCreditData = () => {

        const { film } = this.props

        return film && film.credits && film.credits.map(C => {
            return (
                <CreditName pos={C.creditId && C.creditId.name} data={this.getData(C.creditType)}/>
            )
        })
    }

    getData = (names) => {

        var nameList = []
        names && names.length > 0 && names.forEach(name => {
            nameList.push(name.creditsInsideType)
        });

        return nameList;

    }
    render() {
        const { film } = this.props
        return (
            <div class="sd_films_about_film">
                <div className="sd_film_artists_credits_sec">
                    <div className="sd_film_artists_header">
                        <h2 className="sd_textuppercase">Info</h2>
                    </div>
                    <ul>
                        <CreditName pos="Year" data={[film && film.year]} />
                        <CreditName pos="Category" data={[film && film.category && film.category.ctaText]} />
                        <CreditName pos="Country" data={[film && film.country]} />
                        <CreditName pos="Run time" data={[film && film.runTime + ' Min', film && film.company, film && film.website]} />
                    </ul>
                </div>

                <div className="sd_film_artists_credits_sec">
                    <div className="sd_film_artists_header">
                        <h2 className="sd_textuppercase">Credits</h2>
                    </div>

                    <ul>
                           {
                               this.getCreditData()
                           }
                    </ul>
                    {/* <ul>
                        <CreditName pos="Director" data={['Angel Manuel Soto']}/>
                        <CreditName pos="Screcenwriter" data={['Angel Manuel Soto']}/>
                        <CreditName pos="Story By" data={['Angel Manuel Soto']}/>
                        <CreditName pos="Producers" data={['Angel Manuel Soto','Angel Manuel Soto','Angel Manuel Soto']}/>                                     
                        <CreditName pos="Executive Producers" data={['Angel Manuel Soto']}/>
                        <CreditName pos="Director Of Photography" data={['Angel Manuel Soto']}/>
                        <CreditName pos="Production Designer" data={['Angel Manuel Soto']}/>
                        <CreditName pos="Editor" data={['Angel Manuel Soto','Angel Manuel Soto','Angel Manuel Soto']}/>                                     
                        <CreditName pos="Casting" data={['Angel Manuel Soto']}/>
                        <CreditName pos="Music" data={['Angel Manuel Soto']}/>
                        <CreditName pos="Costume Designer" data={['Angel Manuel Soto']}/>
                        <CreditName pos="Actors" data={['Angel Manuel Soto','Angel Manuel Soto','Angel Manuel Soto']}/>
                    </ul> */}
                </div>
            </div>
        )
    }
}

export default AboutFilm;