import React, {useEffect, useState} from 'react';
import Message from "./Message";
import {useSelector} from "react-redux";
import usePrevious from "../common/hooks/usePrevious";
import service from "../common/service";
// import {CONSTANTS} from "../common/constant";

const Service = new service();

const ChatMessages = React.memo(() => {
    // const _FILM = useSelector(e => e.film)
    const _C = useSelector(e => e.core)
    const _MESSAGES = useSelector(e => e.messages)
    const _MP = useSelector(e => e.messagePagination)
    const _JM = useSelector(e => e.jumpMessage)
    const _ID = useSelector(e => e._id)
    const _C_ID = useSelector(e => e.channelId)

    const [bottom, setBottom] = useState(true)
    const prevState = usePrevious({bottom});
    const _C_S = useSelector(e => e.chatStatus);

    useEffect(() => {
         if (_C?.screen?._id) getMessages();
         // eslint-disable-next-line 
    }, [_C])

    useEffect(() => {
        if (_JM && _JM.length !== 0) {
            const i = _MESSAGES.findIndex(e => e._id === _JM)
            const objDiv = document.getElementById("live-chat-messages");
            if (i > -1 && _MESSAGES[i].senderId === _ID) objDiv.scrollTop = objDiv.scrollHeight;
            else {
                if (prevState && prevState.bottom) objDiv.scrollTop = objDiv.scrollHeight;
            }
        }
        // eslint-disable-next-line
    }, [_JM])

    useEffect(() => {
        if (_MP && _MP.page) {
            if (_MP.page === 1) {
                const objDiv = document.getElementById("live-chat-messages");
                objDiv.scrollTop = objDiv.scrollHeight;
            } else {
                const i = _MESSAGES.length - _MP.pagingCounter;
                if (i && _MESSAGES[i]) {
                    const messageDiv = document.getElementById(_MESSAGES[i]._id);
                    if (messageDiv) messageDiv.scrollIntoView({block: "nearest"});
                }
            }
        }
        // eslint-disable-next-line
    }, [_MP]);

    useEffect(() => {
        const objDiv = document.getElementById("live-chat-messages");
        if(_C_S === 1) objDiv.scrollTop = objDiv.scrollHeight;
    }, [_C_S])  

// eslint-disable-next-line
    const getMessages = () => {
        Service.sendevent('userPrivateScreening|getPublishedMessage', {
            channelId: _C.channelId || _C_ID,
            // limit: CONSTANTS.CHAT_PAGINATION_LIMIT,
            // page: _MP?.nextPage ? _MP.nextPage : 1,
            screeningType: 'PrivateScreeningPartnerScreen'
        })
    }

    const handleScroll = e => {
        if (e.target.scrollTop === 0) {
             // if (_MP &&_MP.hasNextPage) getMessages();
        }
        setBottom(e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight - 100);
    };

    return (
        <React.Fragment>
            <div className="sd_chat_content_wpr" id="live-chat-messages" onScroll={handleScroll}>
                {_MESSAGES && _MESSAGES.map(data => {
                    return <React.Fragment key={data._id}>
                        <Message
                            image={data.image}
                            isModerator={data.isModerator}
                            senderName={data.senderName}
                            message={data.message}
                            id={data._id}
                        />
                    </React.Fragment>
                })}
            </div>
        </React.Fragment>
    );
})

export default ChatMessages;