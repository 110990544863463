import React, { Component } from "react";
import Routers from "./routes";
import {
  BrowserRouter as Router,
} from "react-router-dom";
import history from "./history";
import Idle from 'react-idle'
import Cookies from "universal-cookie";

const cookies = new Cookies();


class App extends Component {


  componentDidMount() {
    // add redirect on master 
    if (window.location.hostname === 'privatescreening.sundance.org') {
      window.location.href = process.env.REACT_APP_HOME_URL
    }
    
    setInterval(function() {
      let User = cookies.get("privateScreeningUser");
      if (User && User.token) {
        let currDate = new Date();
        let Time = process.env.REACT_APP_IDLE_LOGOUT_TIME
        currDate.setTime(currDate.getTime() + (JSON.parse(Time)));
        cookies.set("privateScreeningUser", User, {domain: process.env.REACT_APP_COOKIE_DOMAIN, path: "/", expires: currDate });
      }
    }, 10000);
}

logout = () => { 
  let UserNew = cookies.get("privateScreeningUser");
  if (UserNew && UserNew.token) {
      // eslint-disable-next-line
    document.cookie = "privateScreeningUser" + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    cookies.remove("privateScreeningUser", { domain: process.env.REACT_APP_COOKIE_DOMAIN, path: "/" });
    // eslint-disable-next-line
    document.cookie = "SundanceUserProfile" + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    cookies.remove("SundanceUserProfile", { domain: process.env.REACT_APP_COOKIE_DOMAIN, path: "/" });
    // eslint-disable-next-line
    document.cookie = "fromTrailer" + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    cookies.remove("fromTrailer", { domain: process.env.REACT_APP_COOKIE_DOMAIN, path: "/" });
    cookies.remove("USERHeader", { domain: process.env.REACT_APP_COOKIE_DOMAIN, path: "/" });
    
    localStorage.clear();
    sessionStorage.clear()
    window.open(process.env.REACT_APP_SELF_URL + 'sign-in',"_self")
  }    
};
  render() {
    return (
      
      <Router history={history}>
          <Idle timeout={process.env.REACT_APP_IDLE_LOGOUT_TIME} render={({ idle }) =><h1>{idle ? this.logout() : "" }</h1>} />
          <Routers history={history}/>
      </Router>
    )
  }
}

export default App;

