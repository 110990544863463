import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import service from "../common/service";
let sub;
class Newsletter extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: '',
			check: false,
		}
		this.apiServe = new service();
		this.validator = new SimpleReactValidator({
			autoForceUpdate: this,
			validators: {
			  emailCustom: {
				message: "Invalid email. Please try again",
				rule: (val, params, validator) => {
				  return (
					validator.helpers.testRegex(
					  val,
					  // eslint-disable-next-line
					  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i
					) && params.indexOf(val) === -1
				  );
				},
				required: true,
			  },
			},
		  });
	}
	componentWillUnmount(){
		sub.unsubscribe()
	}
	componentDidMount(){
		sub = this.apiServe.getevent().subscribe((response) => {
		  switch (response.event) {
			case "newsUpdate|create":
			  if (response.error) {
				this.openPopup("", [response.data.error], false);
	
				this.setState({ subscribeMsg: false });
	
				return false;
			  } else {
				this.setState({
				  email: "",
				});
				this.validator.hideMessages();
				this.setState({ subscribeMsg: true });
				setTimeout(() => {
				  this.setState({ subscribeMsg: false });
				}, 3000);
			  }
			  break;
			default:
			  break;
		  }
		});
	  };

	//   componentWillUnmount(){
	// 	sub.unsubscribe();
	//   };

	handleBlur = () => {
		if (this.validator.allValid()) {
			this.validator.hideMessages();
		} else {
			this.validator.showMessages();
		}
	};
	submit = (e) => {
		e.preventDefault();
		if (this.validator.allValid()) {
		  let data = {
			email: this.state.email,
		  };
		  this.apiServe.sendevent("newsUpdate|create", data);
		} else {
		  this.setState({ subscribeMsg: false });
		  this.validator.showMessages();
		  this.forceUpdate();
		}
	  };

	render() {
		return (
			<div className="sd_newsletter_sec">
				<div className="sd_container sd_inner_container">
					<h2 className="sd_textuppercase"> Sign Up for Festival News &amp; Updates </h2>

					<div className="sd_newsletter_form">
						<form>
							<div className="sd_newsletter_form_inner">
								<div className="sd_form_group sd_flex">
									<input type="email" placeholder="Enter Your Email…" value={this.state.email}
										// onBlur={this.handleBlur}
										onChange={(e) => this.setState({ email: e.target.value })} />

									<div className="sd_submit_group">
										<button type="submit" onClick={(e)=>this.submit(e)} className="sd_btn sd_btn_black" > Submit </button>
									</div>
								</div>
								<span className="sd_input_error_msg">
									{" "}
									{/* {this.validator.message(
										"email",
										this.state.email,
										"emailCustom"
									)} */}
									 {this.validator.message("email", this.state.email, "required|email")}
								</span>
								{this.state.subscribeMsg ? (
									<span className="sd_input_success_msg">
										Thanks for subscribing!
									</span>
								) : (
										""
									)}
							</div>
							{/* check box */}
							{/* <div className="sd_newsletter_checkbox">
								<label for="check">
									<input type="checkbox" name="check" value="" defaultChecked={this.state.check} onChange={()=> this.setState({check: !this.state.check})} /> 
									Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
									<span class="checkmark"></span>
								</label>
							</div> */}
						</form>
					</div>

				</div>
			</div>
		);
	}
}

export default Newsletter;
