import React, { Component } from "react";
import { Link, withRouter } from 'react-router-dom';
import moment from "moment-timezone";
import Mobile from "../images/header_mobile-logo.svg";
import Cookies from "universal-cookie";
import timezones from '../common/timezones.json';
import { Mountain_Default } from '../store/functions';
import service from "../common/service";
import Mycart from './../components/Mycart'
import { VelocityTransitionGroup } from "velocity-react";
// import sffLogo from "../../src/images/sff_logo.png";

// Components
import HeaderMenu from "../components/HeaderMenu";
import UserProfile from "../components/UserProfile";
import SearchBox from '../components/SearchBox';

const cookies = new Cookies();
// eslint-disable-next-line
let sub;
var zone = moment.tz.guess();
var abbr = moment.tz(zone).format("z");
var diff = moment.tz(zone).format("Z");
const Timezones = timezones.Timezones;
let testZone = Timezones.find(_=>_.Abbreviation === abbr && _.Zone === diff)
let selectedZones = testZone ? testZone : Mountain_Default
let CURRENT_TIME_ZONE = selectedZones;class Header extends Component {
constructor() {
super();
this.state = {
	navFlag: false,
	accountFlag: false,
	loggedIn: false,
	userInfo: "",
	NewTimezone: this.getZones('ALL', true),
	SecondTimezone: this.getSecondZone(),
	timezoneShow:false,
	cartModalShow: false,
	isSearch: false,
};
this.apiserve = new service();
}

getZones(TYPE, IS_FIRST) {

	if(IS_FIRST)
	{
		let timeZone = JSON.parse(localStorage.getItem('TIMEZONE'))  ? JSON.parse(localStorage.getItem('TIMEZONE')).Abbreviation : abbr;
		TYPE = timeZone !== Mountain_Default.Abbreviation ? 'ALL' : timeZone
	}
	

	if(TYPE === 'ALL')
	{
		if(selectedZones) {
			localStorage.setItem('TIMEZONE', JSON.stringify(selectedZones))
			return selectedZones.Name
		}
		else {
			let mountainZone = Timezones.find(_=>_.Abbreviation === Mountain_Default.Abbreviation && _.Zone === Mountain_Default.Zone)
			localStorage.setItem('TIMEZONE', JSON.stringify(mountainZone))
			return mountainZone.Name
		}
	}
	else
	{

		let mountainZone = Timezones.find(_=>_.Abbreviation === Mountain_Default.Abbreviation && _.Zone === Mountain_Default.Zone)
		return mountainZone.Name
	}
}
getTimeZone() {
    var zone = moment.tz.guess(); // "America/Los_Angeles"
    var abbr = moment.tz(zone).format("z");

    var USTimeZone = ["PST", "PDT", "EST", "EDT", "MST", "MDT", "CST", "CDT"];
    var getZone = USTimeZone.find((o) => o === abbr);

    if (getZone) {
      this.setState({ timezone: getZone });
    } else {
      this.setState({
        timezone: `GMT ${moment().format("Z")}`,
      });
    }
  }
  getSecondZone() {
	let timeZone = JSON.parse(localStorage.getItem('TIMEZONE'))  ? JSON.parse(localStorage.getItem('TIMEZONE')).Abbreviation : abbr;

	if(timeZone === Mountain_Default.Abbreviation)
	{
		let mountainZone = Timezones.find(_=>_.Abbreviation === Mountain_Default.Abbreviation && _.Zone === Mountain_Default.Zone)
		localStorage.setItem('TIMEZONE', JSON.stringify(mountainZone))
		return selectedZones.Name
	}
	else
	{
		let mountainZone = Timezones.find(_=>_.Abbreviation === Mountain_Default.Abbreviation && _.Zone === Mountain_Default.Zone)
		return mountainZone.Name
	}
}
componentDidMount() {
this.getTimeZone();
window.addEventListener("resize", this.windowResize);
window.addEventListener("beforeunload", this.windowBeforeUnload);
window.addEventListener('scroll', this.windowScroll, true);

if (localStorage.getItem("myLoginToken")) {
	this.setState({
		loggedIn: true,
	});
}

if (cookies.get("SundanceUserProfile")) {
	this.setState({
		userInfo: cookies.get("SundanceUserProfile"),
	});
}
let particularUser =
      cookies.get("SundanceNewUser") || cookies.get("particularNewUser");

    if (!particularUser) {
      let Zone = localStorage.getItem('TIMEZONE')
          localStorage.clear();
      if(Zone) localStorage.setItem('TIMEZONE', Zone);
    } else {
      let particularUser =
        cookies.get("SundanceNewUser") || cookies.get("particularNewUser");

      if (
        particularUser &&
        particularUser.token &&
        cookies.get("SundanceUserProfile")
      ) {
        // this.getProfile();
        this.setState({
          loggedIn: true,
          userInfo: cookies.get("SundanceUserProfile"),
        });
      }
	}
	setTimeout(() => {
		this.updateCartCount()
	  }, 400);
}

updateCartCount = () => {
    this.apiserve.sendevent("cart-purchase|getCartCount", {});

    sub = this.apiserve.getevent().subscribe(async (response) => {
      switch (response.event) {
        case "cart-purchase|getCartCount":
          if (!response.error) {
            this.setState((prevState) => ({
              ...prevState,
              cartCount: response.data.count,
            }));
            cookies.set("CartCount", response.data.count, {
              domain: ".sundance.org",
              path: "/",
            });
          }
          break;
        default:
          break;
      }
    });
  }

ShowTimeMenu = () => {
    this.setState({
      timezoneShow: !this.state.timezoneShow
    })
}
// Before unload event
windowBeforeUnload = () => {		   
setTimeout(() => {
	window.scrollTo(0,0)
}, 0);
}

// Toggle User Menu
toggleUserMenu = () => {
this.setState({ 
	accountFlag: !this.state.accountFlag,
	navFlag: false  
});
if(!this.state.accountFlag || this.state.navFlag) {
	document.querySelector('body').classList.add('no-scroll');
	document.querySelector('nav').classList.add('menu-active');
} else {
	document.querySelector('body').classList.remove('no-scroll');
	document.querySelector('nav').classList.remove('menu-active');
}
}

// Toggle Navigation Menu
toggleNavMenu = () => {
this.setState({ 
	navFlag: !this.state.navFlag, 
	accountFlag: false 
});		
if(this.state.accountFlag || !this.state.navFlag) {
	document.querySelector('body').classList.add('no-scroll');
	document.querySelector('nav').classList.add('menu-active');
} else {
	document.querySelector('body').classList.remove('no-scroll');
	document.querySelector('nav').classList.remove('menu-active');
}
}

// Window Resize Event
windowResize = () => {
// Hide Nav's on desktop
if(window.innerWidth > 1366) {
	this.setState({ 
		navFlag: false, 
		accountFlag: false 
	});	

	if(document.querySelector('.no-scroll') || document.querySelector('.menu-active')){
		document.querySelector('body').classList.remove('no-scroll');
		document.querySelector('nav').classList.remove('menu-active');
	}
}
};

// Window Scroll Event
windowScroll = (event) => {
const winScroll = document.body.scrollTop || document.documentElement.scrollTop;

if(winScroll > 0) {
	document.querySelector('header') && document.querySelector('header').classList.add('sd_sticky_header');
} else {
	document.querySelector('header') && document.querySelector('header').classList.remove('sd_sticky_header');
}
}

goto(Link) {
window.open(Link, "_self")
}

signinRegister() {
window.open(process.env.REACT_APP_HOME_URL + 'sign-in',"_self")
}
changeTimeZone(timeZone) {
    if (timeZone === Mountain_Default.Abbreviation) {
      this.setState({
        NewTimezone: this.getZones(Mountain_Default.Abbreviation),
        SecondTimezone: this.getZones('ALL')
      })
      let mountainZone = Timezones.find(_ => _.Abbreviation === Mountain_Default.Abbreviation && _.Zone === Mountain_Default.Zone)
      localStorage.setItem('TIMEZONE', JSON.stringify(mountainZone))
    }
    else {
      this.setState({
        NewTimezone: this.getZones('ALL'),
        SecondTimezone: 'Mountain Standard Time'
      })
    }

    window.location.reload();
  }
// getTimeZone() {
// var zone = moment.tz.guess(); // "America/Los_Angeles"
// var abbr = moment.tz(zone).format("z");

// var USTimeZone = ["PST", "PDT", "EST", "EDT", "MST", "MDT", "CST", "CDT"];
// var getZone = USTimeZone.find((o) => o === abbr);

// if (getZone) {
// 	this.setState({ timezone: getZone });
// } else {
// 	this.setState({
// 		timezone: `GMT ${moment().format("Z")}`,
// 	});
// }

// }
openPopup = (title) => {
    this.setState({
      isSearch: !this.state.isSearch
    });
  }

cartClick = (e) => {
    e.preventDefault();
    this.setState({
      cartModalShow: !this.state.cartModalShow
    }, () => {
      this.state.cartModalShow ? document.querySelector("body").classList.add("no-scroll") : document.querySelector("body").classList.remove("no-scroll");
    })
    this.updateCartCount()
  }
closeCartModal = () => {
this.setState({
	cartModalShow: false
})
document.querySelector("body").classList.remove("no-scroll");
}

closePopups = () => {
	this.setState({
		isSearch: false,
	  });
}

render() {
let { navFlag, accountFlag,cartCount } = this.state; 
let userInfo =cookies.get("SundanceUserProfile");
return (
	<div className="sd_header_section header_slider_section sd_relative">	
		{
          this.state.isSearch &&
          <SearchBox history={this.props.history} closePopups={this.closePopups} />

        }	
		<header> 
			{ /* Add Class sd_sticky_header here to make header sticky on scroll */}
			<div className="sd_inner_container">
				<div className="sd_header_sec_wpr">

					{/* Timezone */}
					

					<div className="sd_header_wpr sd_flex"> 

						{/* Logo */}
						<div className="sd_header_logo">
						<Link to="/" className="sd_desktop_logo">
							<img src="/images/sff_logo.png" alt=" "/>
								{/* <img src={desktop_logo} alt="logo" /> */}
								{/* <p className="sd_textuppercase">
								Sundance Institute presents
								</p>
								<h3 className="sd_textuppercase">
								Sundance Film Festival 2021
								</h3> */}
							</Link>

							<Link to="/" className="sd_mobile_logo sd_hidden">
								<img
								src="/images/sff_logo.png"
								alt="Sundance Film Festival 2021"
								title="Sundance Film Festival 2021"
								/>
							</Link>
						</div>

						{this.props.isMenuShow ? 
							<>
								{/* Toggle Nav Button */}
								<div className={`toggle_menu sd_hidden sd_absolute ${navFlag && 'navmenu-active'}`}>
									<button onClick={this.toggleNavMenu.bind(this)}>
										<span></span>
										<span></span>
										<span></span>
									</button>
								</div>

								{/* Navigation */}
								<nav className={`sd_header_nav sd_flex ${navFlag && 'navmenu-active'} ${accountFlag && 'usermenu-active'} `}>
									{/* Menu */}
									<HeaderMenu history={this.props.history} navFlag={navFlag} userInfo={userInfo} cartCount={cartCount === undefined ? 0 : cartCount}
									updateCartCount={this.updateCartCount}
									isActive={this.state.cartModalShow}
									closeCartModal={this.closeCartModal}
									cartPage={this.cartClick}
									isSearchOpen={this.openPopup}
									/>

									{userInfo && <UserProfile
										history={this.props.history}
										userInfo={userInfo}
										cartCount={cartCount === undefined ? 0 : cartCount}
										updateCartCount={this.updateCartCount}
										isActive={this.state.cartModalShow}
										closeCartModal={this.closeCartModal}
										cartPage={this.cartClick}
										isSearchOpen={this.openPopup}
									/>
}
								</nav>
							</>
							: ''
						}

					</div>
				</div>
				
			</div>
			{/* <div className="header_timezone sd_flex sd_aligncenter sd_flexwrap" onClick={this.ShowTimeMenu}>
				<span className="sd_textuppercase label_timezone">TIMEZONE</span>
				<div className={`sd_p_cursor sd_flex sd_aligncenter select_timezone ${this.state.timezoneShow ? 'active': ''}`} >
					<input name='timezoneSelect' disabled value={this.state.NewTimezone}/> 

					{
						CURRENT_TIME_ZONE.Abbreviation !== Mountain_Default.Abbreviation &&

						<>
							<span className="sd_p_cursor"><svg width="11" height="6" viewBox="0 0 11 6" fill="none"><path d="M1.29285 0.714355L5.75626 5.00007L10.0071 0.918437" stroke="white" stroke-width="1.218"/></svg></span>
							<ul>
								<li onClick={()=>{ this.changeTimeZone(this.state.NewTimezone === Mountain_Default.Name ? moment.tz.guess() : Mountain_Default.Abbreviation) }}>{this.state.SecondTimezone}</li>
							</ul>
						</>
					}
					
					
				</div>
			</div> */}
			<VelocityTransitionGroup enter={{ animation: "slideDown" }} leave={{ animation: "slideUp" }}>
            {
              this.state.cartModalShow &&
              <div>
                <Mycart history={this.props.history} updateCartCount={this.updateCartCount} closeCartModal={this.closeCartModal} />
              </div>
            }
          </VelocityTransitionGroup>
		</header>

	</div>
)
}
}

export default withRouter(Header);
