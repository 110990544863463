import CryptoJS from "crypto-js";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export const Encryption = (data) => {
    return CryptoJS.AES.encrypt(
        JSON.stringify(data),
        process.env.REACT_APP_SECRET_KEY
    ).toString();
};

export const Decryption = (data) => {
    const bytes = CryptoJS.AES.decrypt(data, process.env.REACT_APP_SECRET_KEY);

    try {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    } catch (error) {
        return []
    }
};
export const openInNewTab = (URL) => {
    const newWindow = window.open(URL, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}
export const logout = (e) => {
    if(e) e.preventDefault();
    // eslint-disable-next-line
    document.cookie = "SundanceNewUser" + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    cookies.remove("SundanceNewUser", {domain: process.env.REACT_APP_COOKIE_DOMAIN, path: "/",});
    cookies.remove("sd_prev", {domain: process.env.REACT_APP_COOKIE_DOMAIN, path: "/",});
    cookies.remove("CartCount", {domain: process.env.REACT_APP_COOKIE_DOMAIN, path: "/",});
    cookies.remove("privateScreeningUser", { domain: process.env.REACT_APP_COOKIE_DOMAIN, path: "/" });
    cookies.remove("USERHeader", { domain: process.env.REACT_APP_COOKIE_DOMAIN, path: "/" });
    cookies.remove("PrivateScreenUser", { domain: process.env.REACT_APP_COOKIE_DOMAIN, path: "/" });
    cookies.remove("SundanceUserProfile", { domain: process.env.REACT_APP_COOKIE_DOMAIN, path: "/" });
    cookies.remove("STYXKEY_sundanceUser", { domain: process.env.REACT_APP_COOKIE_DOMAIN, path: "/" });
    cookies.remove("myEventiveLoginToken", { domain: process.env.REACT_APP_COOKIE_DOMAIN, path: "/" });
    cookies.remove("myLoginToken", { domain: process.env.REACT_APP_COOKIE_DOMAIN, path: "/" });
    cookies.remove("forcelogoutkey", { domain: process.env.REACT_APP_COOKIE_DOMAIN, path: "/" });

    localStorage.clear();
    sessionStorage.clear()
    document.cookie = ''
    window.open(process.env.REACT_APP_SELF_URL + "sign-in", "_self");
}