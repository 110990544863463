import React from 'react';
import { Link } from 'react-router-dom';


const ShortPopup = (props) => {
    const { _C, closePopup, _STATUS, startQnA, _MOVIE_STATUS,hide_close } = props
    return (
        <div className="sd_popup sd_short_popup sd_rating_popup">

            <div className="sd_popup_inner">
                {/* Close button */}
                {!hide_close && <button class="sd_close_popup" onClick={closePopup} >
                    <span></span>
                    <span></span>
                </button>}

                <div className="sd_popup_sec">

                    <div className="sd_popup_right_sec sd_popup_sec_wpr">
                        <div className="sd_popup_rating_header">
                            {/* <h3 className="sd_textuppercase">Our Q&amp;A begins shortly.</h3> */}
                            <p>Thank you for watching</p>
                            <span className="sd_textuppercase">{_C && _C.screen?.filmId?.title}</span>
                        </div>
                        <div className="sd_popup_link_wpr">
                            {_STATUS === 'Now Playing' && <Link onClick={startQnA} className="sd_link_btn sd_link_btn_pink">
                                {_MOVIE_STATUS === 'continuous' && _C?.screen?.qaReplayLink ? "Join the Q&A" : 'Back to Home'}

                                {/* <img src="/images/slider_arrow_right_pink.svg" alt="Q&A" title="Q&A"/> */}
                            </Link>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default ShortPopup;