import React, { Component } from 'react'

class QaMessage extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             like:false
        }
    }
    LikeQuestion = () => {
        this.setState({
            like : !this.state.like
        })
    }
    
    render() {
        return (
            <div className="chat_message">
                <div className={`chat_user_data ${this.props.moderator ? 'active_moderator':''} `}>
                    <p><strong>Amma:</strong> Lorem ipsum dolor sit amet </p>
                    <span className="pointer like_icon" onClick={this.LikeQuestion}>
                        <svg width="18px" height="16px" viewBox="0 0 18 16" version="1.1" xmlns="http://www.w3.org/2000/svg" >
                            <g id="SCRN" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="PS-08-Live-QA_v3" transform="translate(-1523.000000, -1523.000000)">
                                    <g id="Group-Copy" transform="translate(1523.000000, 1523.000000)" fill={this.state.like ? '#CA8B02' : '#FFFFFF'} fill-rule="nonzero">
                                        <path d="M3.47475994,6.35345478 L0.496435152,6.35345478 C0.218317015,6.35345478 0,6.56691007 0,6.83883476 L0,14.6040751 C0,14.8759998 0.218317015,15.0894551 0.496435152,15.0894551 L3.47475994,15.0894551 C3.75287807,15.0894551 3.97090924,14.8759998 3.97090924,14.6040751 L3.97090924,6.83883476 C3.97119509,6.56691007 3.75287807,6.35345478 3.47475994,6.35345478 Z" id="Path"></path>
                                        <path d="M17.4717229,7.00009001 C17.4717229,6.23294358 16.9326874,5.45657077 15.9032155,5.45657077 L11.2357396,5.45657077 C11.9025219,4.29106177 12.0985853,2.65175098 11.6350869,1.45232147 C11.2946142,0.570116643 10.6439391,0.055338864 9.80192164,0.00296558445 L9.78831384,0.00188012788 C9.24039155,-0.0309549334 8.76606246,0.37012127 8.72051798,0.904708632 C8.60054716,2.09436903 8.0670658,4.1985266 7.30252952,4.94559208 C6.65879719,5.57461417 6.10782008,5.83810875 5.19443111,6.27446229 C5.06224104,6.33769014 4.91783172,6.40661663 4.76509109,6.48097041 C4.76786819,6.51326274 4.76953445,6.54555507 4.76953445,6.5786615 L4.76953445,14.03412 C4.87978542,14.0710255 4.98864783,14.1076596 5.09556627,14.1434797 C6.60297743,14.651202 7.90571615,15.0894551 9.89689854,15.0894551 L13.6704252,15.0894551 C14.7001748,15.0894551 15.2389326,14.3128109 15.2389326,13.5459359 C15.2389326,13.3182614 15.1917219,13.0900441 15.0981336,12.8810937 C15.4411057,12.8208509 15.7413105,12.6596606 15.9637564,12.4129906 C16.216195,12.1326714 16.3553278,11.7600884 16.3553278,11.3638968 C16.3553278,11.1370364 16.3081171,10.9088191 16.2148064,10.7004115 C17.0415498,10.5609303 17.4720007,9.86759491 17.4720007,9.18212908 C17.4720007,8.78458062 17.3270359,8.3843185 17.0443269,8.09124523 C17.3264805,7.79790059 17.4717229,7.39763848 17.4717229,7.00009001 Z" id="Path"></path>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </span>
                </div>
            </div>
        )
    }
}

export default QaMessage;