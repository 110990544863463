import React from 'react'
import { useDispatch, useSelector } from "react-redux";
import service from '../common/service';

const Service = new service()
export default function FourHourPopup() {
    const _FILM = useSelector(e => e.film)
    const _C = useSelector(e => e.core)
    const dispatch = useDispatch();
    const StartFourHourWindow = (TYPE) => {
        if (TYPE === 'YES') {
        Service.sendevent('userPrivateScreening|setFiveHoursEnable', { filmId: _C?.screen?.filmId?._id, screeningId: _FILM?.channelId,});
        // _C.screen?.filmId?._id, screeningId: _C.screen?._id
        // dispatch({ type: 'SET_FIVE_HOUR_WINDOW_STATUS', payload: TYPE === 'YES' ? 'Started' : '' })
        }else{
            dispatch({ type: 'SET_FIVE_HOUR_WINDOW_STATUS', payload: TYPE === 'YES' ? 'Started' : '' })
            window.open(process.env.REACT_APP_HOME_URL+'my-account?tab=2','_self')
            // history.push('/')   
        }
     }
    return (
        <React.Fragment>
            <div className="sd_popup sd_custom_popup sd_Would_you_like_popup">
                <div className="sd_popup_inner">
                    <p>Once you go to the next page, you’ll have {_FILM.category === 'INDIE_SERIES' ? 'twelve' : 'five'} hours to finish watching this film. Once you start a film, you can pause it and take as many breaks as you’d like. Just be sure to finish watching within {_FILM.category === 'INDIE_SERIES' ? 'twelve' : 'five'} hours of your start time.</p>
                    <h2>Would you like to start your <span>{_FILM.category === 'INDIE_SERIES' ? '12' : '5'}-hour viewing window</span> now?</h2>
                    <div className="sd_Would_you_btn">
                        <button className="sd_btn_yes" onClick={() => StartFourHourWindow('YES')}>Yes.</button>
                        <button className="sd_btn_Not_yet" onClick={() => StartFourHourWindow('NO')} >Not yet. <span>Go Back.</span></button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}