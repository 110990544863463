import React, { useState } from 'react';
import moment from "moment";
import { YearPicker, MonthPicker, DayPicker } from 'react-dropdown-date';
import { useDispatch, useSelector } from "react-redux";
import Service from "../common/service";
import Cookies from 'universal-cookie';
import SimpleReactValidator from "simple-react-validator";
import { Link } from 'react-router-dom';
const cookies = new Cookies();
const service = new Service();
const validator = new SimpleReactValidator();

const VerifyAge = React.memo(() => {
    const dispatch = useDispatch()
    const _FILM = useSelector(e => e.core)
    // eslint-disable-next-line
    const _ID = useSelector(e => e._id)
    // eslint-disable-next-line
    const [temp, setTemp] = useState('');
    const [day, setDay] = useState('');
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    const [check, setCheck] = useState();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const save = () => {
        if (validator.allValid() && day.length !== 0 && month.length !== 0 && year.length !== 0 && check) {
            const date = moment(`${year}-${parseInt(month) + 1}-${day}`, 'YYYY-MM-DD').format()
            const age = calcAge(date)
            let user = cookies.get("privateScreeningUser");
            const data = {
                "user": user?.user?._id,
                "filmId": _FILM?.screen?._id,
                "filmType": 'PrivateScreeningPartnerScreen',
                "birthday": date,
                "age": age,
                "terms": check
            }
            service.sendevent('userScreening|setAgeVerification', data)
        } else {
            validator.showMessages();
            setTemp(Math.random())
            forceUpdate()
        }
    }
    const calcAge = (dateString) => {
        const birthday = +new Date(dateString);
        return ~~((Date.now() - birthday) / (31557600000));
    }
    // eslint-disable-next-line
    const close = () => {
        dispatch({ type: 'AGE_VERIFICATION', padding: '' })
    }
    return (
        <div className="sd_popup sd_custom_popup sd_verify_age_popup">
            <div className="sd_popup_inner">
                {/* Close button */}
                {/* <button className="sd_close_popup" onClick={close}>
                    <span></span>
                    <span></span>
                </button> */}

                <div className="sd_popup_header">
                    <div className="sd_films_content_warning">
                        <p>Warning: This film contains explicit content</p>
                    </div>
                    <h2 className="sd_textuppercase">You must be 18 or older to view this film. Please enter your date of birth below.</h2>
                    {/* <p className="sd_popup_header_desc" dangerouslySetInnerHTML={{__html: _FILM?.screen?.filmId?.explicitContent}}/> */}
                </div>

                <div className="sd_popup_header_desc">

                    <div className="dropdown_date">

                        <div className="sd_age_verify_date_month sd_age_verify_date_inner">
                            <MonthPicker
                                defaultValue={'month'}
                                long
                                year={year}
                                required={true}
                                value={month}
                                onChange={(e) => setMonth(e)}
                                id={'month'}
                                name={'month'}
                            />
                            <span className="sd_input_error_msg">{validator.message("month", month, 'required')}</span>

                        </div>


                        <div className="sd_age_verify_date_day sd_age_verify_date_inner">
                            <DayPicker
                                defaultValue={'day'}
                                year={year}
                                month={month}
                                endYearGiven
                                required={true}
                                value={day}
                                onChange={(e) => setDay(e)}
                                id={'day'}
                                name={'day'}
                            />
                            <span className="sd_input_error_msg">{validator.message("day", day, 'required')}</span>

                        </div>

                        <div className="sd_age_verify_date_year sd_age_verify_date_inner">
                            <YearPicker
                                defaultValue={'year'}
                                start={1900}
                                end={2020}
                                reverse
                                required={true}
                                onChange={(e) => setYear(e)}
                                id={'year'}
                                name={'year'}
                            />
                            <span className="sd_input_error_msg">{validator.message("year", year, 'required')}</span>

                        </div>
                    </div>
                    <div className="sd_verify_age_confirm">
                        <div className="sd_form_group">
                            <input type="checkbox" id="sd_verify_confirm" checked={check} onChange={e => setCheck(e.target.checked === true ? e.target.checked : '')} />
                            <label htmlFor="sd_verify_confirm">I confirm the information I provided is true and correct.</label>
                        </div>
                        <span className="sd_input_error_msg">{validator.message("agree", check, 'required')}</span>
                    </div>

                    <Link className="sd_link_btn sd_link_btn_pink sd_justcenter" onClick={save}>
                        Continue
                        <img src="/images/slider_arrow_right.svg" alt="Watch without Audio Description"
                            title="Watch without Audio Description" />
                    </Link>
                </div>
            </div>
        </div>
    )
})

export default VerifyAge;