import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from "react-redux";
import { store, persistor } from "./store/store";
import { PersistGate } from "redux-persist/integration/react";



document.addEventListener('keypress', logKey);

let logger = function() {
    /* +-------------------------------------------------------------------+
     desc: Enable or disable logger and disable warn function.
     +-------------------------------------------------------------------+ */
    let oldConsoleLog = null;
    let pub = {};

    pub.enableLogger = function enableLogger() {
        if (oldConsoleLog == null) return;
        window['console']['log'] = oldConsoleLog;
        console.log('=== LOG ENABLED ===');
    };
    pub.disableLogger = function disableLogger() {
        oldConsoleLog = console.log;
        window['console']['log'] = () => {};
    };
    pub.disableWarn = function disableWarn() {
        window['console']['warn'] = () => {};
    };
    return pub;
}();

function logKey(e) {
    /* +-------------------------------------------------------------------+
     desc: define log enable disable key.
     +-------------------------------------------------------------------+ */
    if (e.ctrlKey && e.shiftKey && e.altKey) {
        switch (e.code) {
            case 'KeyE':
                logger.disableLogger();
                break;
            case 'KeyS':
                logger.enableLogger();
                break;
            default:
                break;
        }
    }
}

if (window.location.hostname === 'privatescreening.sundance.org') {
    logger.disableLogger();
    logger.disableWarn();
} else {
    logger.disableWarn();
}


ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <App />
        </PersistGate >
      </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
