// import React, { Component } from 'react';

// class HeroHeader extends Component {
//     render() {
//     const {title, date, user, partner} = this.props
//         return (
//             <div className="sd_home_count">
//                 <div className="sd_count_down_sec">
//                     {
//                         partner && partner.partnerLogo ?  <div className="sd_logo_sec">
//                         <p className="sd_textuppercase">Premiere Brought to you by</p>
//                     <img src={partner.partnerLogo && !partner.partnerLogo.includes('http') ? process.env.REACT_APP_IMAGE_URL + partner.partnerLogo : 
//                                                partner.partnerLogo && partner.partnerLogo.includes('http') ? partner.partnerLogo : ''} alt="Sponsors" title="Sponsors"/>
//                     </div> :''
//                     }
//                     <div className="sd_inner_container sd_count_down_wpr">
//                         <span>{title? title : ''}</span>
//                         <h2 className="sd_textuppercase"> {date ? date :''}</h2>
//                     </div>

//                     {/* <h4 className="sd_textuppercase">Live Q&amp;A begins after the film</h4> */}
//                 </div>
//             </div>
//         )
//     }
// }
// export default HeroHeader;  

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import service from "../common/service";
import { Link, useParams } from "react-router-dom";
import Timer from "./Timer";
import Cookies from 'universal-cookie';
import { CONSTANTS } from "../common/constant";

const Service = new service()
const cookies = new Cookies();
const timeSlot = 1;
let notShowing = true;

const HeroHeader = React.memo((props) => {

    const { qa, id } = useParams();
    const dispatch = useDispatch()
    const _FILM = useSelector(e => e.film)
    const _C = useSelector(e => e.core)
    const _C_ID = useSelector(e => e.channelId)
    const _AGE = useSelector(e => e.age)
    const _MOVIE_EVENTIVE = useSelector(e => e.movieEventiveId)
    const _MY_TICKETID = useSelector(e => e.myTicketId)
    const _AUDIO = useSelector(e => e.audio) || false
    const _STATUS = useSelector(e => e.status)
    const _MOVIE_STATUS = useSelector(e => e.movieStatus)
    const FIVE_HR = useSelector(e => e.fiveHours)
    const VIDEO_TYPE = useSelector(e => e.videoPlayerType)
    
    // const _IS_MORE_TIME = useSelector(e => e.isMoreTime)

    const partner = _C && _C.screen && _C.screen.filmId && _C.screen.filmId.updatedBy
    const title = _C && _C.screen && _C.screen.filmId && _C.screen.filmId.title
    // const screen = _C && _C.screen && _C.screen.filmId
    const [time, setTime] = useState(props.remainingTime);
    const [isMoreTime, setIsMoreTime] = useState(false);
    const [, setFromTrailer] = useState(false)
    // fromTrailer
    useEffect(() => {
        componentLoad()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        setTime(props.remainingTime)
    }, [props.remainingTime])

    useEffect(() => {

        // krushal.sonani, a year ago   (January 27th, 2021 2:22pm) 
        // update for safari timer issue fixed ↓↓↓↓↓↓↓↓↓

        document.removeEventListener('visibilitychange', play);
        document.addEventListener('visibilitychange', play);

        return () => document.removeEventListener('visibilitychange', play);
        
        // eslint-disable-next-line
    }, [_STATUS, props.qa]);

    useEffect(() => {
        if (!notShowing && FIVE_HR === 'Started' && _AGE === 'verified') {
            /**
             * If movie is with explicit content and user's age is verified then show moviw
             * */
            notShowing = true
            playMovieFirst('FIRST')
        } else if (FIVE_HR === 'Waiting' || _AGE === 'checking') {
            notShowing = false
        }
        // eslint-disable-next-line
    }, [_AGE, FIVE_HR])
    const play = () => {
        if (document.hidden) {
        } else {
            componentLoad();
        }
    };
    const componentLoad = () => {
        /**
         * if Premier => start trailer before 15 minites
         * else do nothing
         *
         * if time is before trailer time - do nothing
         * if time is same as trailer time - Get trailer token and play trailer
         * If time is after trailer time and before movie time - get movie token and play movie
         * If time is after movie timer - get movie token and play movie
         * If time is (before movie end time - 5) - Show popups
         * If time is after movie time - Watch Q&A
         * */
        if (_C && _C.date) {
            /**
             * check if movie type is premier or not
             * */
            const current = moment()
            // const premiereStart = moment(_C && _C.date).subtract(CONSTANTS.PREMIERE_TIME, 'minutes')
            // const premiereEnd = moment(_C && _C.date)
            const movieStart = moment(_C && _C.date)
            const movieEnd = moment(_C && _C.screen.screeningendDate)

            const now = moment();
            let date = '';
            let duration = 0;
            if (_FILM && _FILM.screeningType === "PREMIERE" && current.isBefore(movieStart)) {
                // Do nothing
            } else if (_C) {
                date = moment(_C && _C.date).subtract(CONSTANTS.PREMIERE_TIME, 'minutes')
                duration = ((CONSTANTS.PREMIERE_TIME * 60)) - moment.duration(now.diff(date)).asSeconds();
                if (duration >= 0 && duration < (CONSTANTS.PREMIERE_TIME * 60)) {
                    //  setTime(duration)
                    dispatch({ type: 'SET_MOVIE_STATUS', payload: 'continuous' })
                    movieCountDown();
                    startTrailer();
                    setIsMoreTime(false)
                    setFromTrailer(true)
                    dispatch({ type: 'SET_MORE_TIME_FLAG', payload: false })
                } else if (current.isAfter(movieStart) && current.isBefore(movieEnd)) {
                    // if (!fromTrailer) dispatch({ type: 'SET_MOVIE_STATUS', payload: '' })
                    dispatch({ type: 'SET_MOVIE_STATUS', payload: 'continuous' })
                    setIsMoreTime(false)
                    dispatch({ type: 'SET_MORE_TIME_FLAG', payload: false })

                    //    if (_AGE === 'verified' && FIVE_HR === 'Started')
                    startMovie();
                } else {
                    if (current.isAfter(movieEnd)) {
                        dispatch({ type: 'SHOW_QNA', payload: false })
                        //startQnA()
                        dispatch({ type: 'SET_MOVIE_STATUS', payload: '' })
                        // if (_AGE === 'verified' && FIVE_HR === 'Started') 
                        startMovie();
                    }
                    else {
                        setIsMoreTime(true)
                        dispatch({ type: 'SET_MORE_TIME_FLAG', payload: true })
                        //  startTrailer();
                        props.refresh();
                    }
                }
            }
        }
    }
    const joinChannel = () => {
        const data = { film: _C.screen?.filmId?._id, screeningId: _C.screen?._id };
        Service.sendevent("userPrivateScreening|joinChannel", data);
    }

    const startTrailer = () => {
        dispatch({ type: 'SET_MOVIE_STATUS', payload: 'continuous' })
        dispatch({ type: 'SET_STATUS', payload: 'Coming Soon' })
        // if (VIDEO_TYPE === 1) {
        //     Service.sendevent('userScreening|getPlayToken', { filmId: _C?.screen?.filmId?._id, screeningId: _C?.screen?._id, audio: _AUDIO || false });
        // }else{
            Service.sendevent('userScreening|getEventivePlayToken', { filmId: _C?.screen?.filmId?._id, screeningId: _C?.screen?._id, audio: _AUDIO || false,myTicketId:_MY_TICKETID,movieEventiveId:_MOVIE_EVENTIVE  });
        // }
        if (_C.channelId || _C_ID)
            Service.sendevent("userScreening|channelStatus", { channelId: _C.channelId || _C_ID });
        if (_C?.screen?._id)
            Service.sendevent('privateScreeningPartner|screeningTrack|create', { screenId: _C?.screen?._id, JoinTheWaitingRoom: true });

        props.refresh();
    }
    const startMovie = () => {
        let _selfLive = cookies.get("fromTrailer");
        if (_selfLive) {
            dispatch({ type: 'SET_MOVIE_STATUS', payload: 'continuous' })
            dispatch({ type: 'SHOW_QNA', payload: true })
        }
        if (qa) {
            dispatch({ type: 'SET_STATUS', payload: 'End' })
        }
        else {
            dispatch({ type: 'SET_STATUS', payload: 'Now Playing' });
            dispatch({ type: 'SHOW_QNA', payload: false })
        }
        if (FIVE_HR === 'Started' && _AGE === 'verified' && _STATUS === 'Coming Soon' ) {
            debugger
            // if (VIDEO_TYPE === 1) {
            // Service.sendevent('userScreening|getPlayToken', { isMovie: true, filmId: _C?.screen?.filmId?._id, screeningId: _C?.screen?._id, audio: _AUDIO || false });}
            // else{
                Service.sendevent('userScreening|getEventivePlayToken', { isMovie: true, filmId: _C?.screen?.filmId?._id, screeningId: _C?.screen?._id, audio: _AUDIO || false , isSkipTokenCall: sessionStorage.getItem(id) !== null ? true : false,movieEventiveId:_MOVIE_EVENTIVE, myTicketId:_MY_TICKETID });
            // }
            //service.sendevent('digitalscreening|getShift72Token', {filmId: _FILM._id, dateTimeId: date, audio: _AUDIO});
        }
        else {
            notShowing = false
            // dispatch({ type: 'AGE_VERIFICATION', payload: 'checking' })
            if (_AGE !== 'verified' && _AGE !== ''){}
            else if (FIVE_HR !== 'Started' && _AGE === 'verified') {
                // dispatch({ type: 'SET_FIVE_HOUR_WINDOW_STATUS', payload: 'Waiting' })
            }
        }
        if (_C.channelId || _C_ID)
            Service.sendevent("userScreening|channelStatus", { channelId: _C.channelId || _C_ID });

        if (_C?.screen?._id)
            Service.sendevent('privateScreeningPartner|screeningTrack|create', { screenId: _C?.screen?._id, FirstWatchDateTime: new Date() });

        props.refresh();
    }

    const playMovie = (FIRST) => {
        if (FIVE_HR === 'Started' && _AGE === 'verified') {
            dispatch({ type: 'SET_AUDIO', payload: !_AUDIO })
            // if (VIDEO_TYPE === 1) {Service.sendevent('userScreening|getPlayToken', { isMovie: true, filmId: _C?.screen?.filmId?._id, screeningId: _FILM?.channelId, audio: FIRST === 'FIRST' ? _AUDIO : !_AUDIO || false });}
            // else {
                Service.sendevent('userScreening|getEventivePlayToken', { isMovie: true, filmId: _C?.screen?.filmId?._id, screeningId: _FILM?.channelId,  audio: FIRST === 'FIRST' ? _AUDIO : !_AUDIO || false , isSkipTokenCall: sessionStorage.getItem(id) !== null ? true : false, movieEventiveId:_MOVIE_EVENTIVE,myTicketId:_MY_TICKETID });
            // }
        } else if (_AGE !== 'verified' && _AGE !== '') dispatch({ type: 'AGE_VERIFICATION', payload: 'checking' })
        else if (FIVE_HR !== 'Started' && _AGE === 'verified') { dispatch({ type: 'SET_FIVE_HOUR_WINDOW_STATUS', payload: 'Waiting' }) }
    }
    const playMovieFirst = (FIRST) => {
        if (FIVE_HR === 'Started' && _AGE === 'verified') {
            // if (VIDEO_TYPE === 1) {Service.sendevent('userScreening|getPlayToken', { isMovie: true, filmId: _C?.screen?.filmId?._id, screeningId: _FILM?.channelId, audio: FIRST === 'FIRST' ? _AUDIO : !_AUDIO || false });}
            // else {
                Service.sendevent('userScreening|getEventivePlayToken', { isMovie: true, filmId: _C?.screen?.filmId?._id, screeningId: _FILM?.channelId,  audio: FIRST === 'FIRST' ? _AUDIO : !_AUDIO || false  , isSkipTokenCall: sessionStorage.getItem(id) !== null ? true : false, movieEventiveId:_MOVIE_EVENTIVE,myTicketId:_MY_TICKETID });
            // }
        } else if (_AGE !== 'verified' && _AGE !== '') dispatch({ type: 'AGE_VERIFICATION', payload: 'checking' })
        else if (FIVE_HR !== 'Started' && _AGE === 'verified') { dispatch({ type: 'SET_FIVE_HOUR_WINDOW_STATUS', payload: 'Waiting' }) }
    }
    
    // eslint-disable-next-line
    const startQnA = () => {
        dispatch({ type: 'SET_STATUS', payload: 'End' })
        props.watchQA()
    }

    const movieCountDown = () => {
        let a = 0
        setTime(prevState => {
            const newTime = parseInt(prevState - timeSlot);
            if (newTime === 0 && _STATUS !== 'Now Playing' && a === 0) {
                a++;
                if (_MOVIE_STATUS === 'continuous') {
                    dispatch({ type: 'SET_WAITING_ROOM_RUNNING', payload: false });
                    dispatch({ type: 'PLAYER_STATUS', payload: false })
                    joinChannel()
                }
                cookies.set("fromTrailer", { _self: true }, { domain: process.env.REACT_APP_COOKIE_DOMAIN, path: "/", expires: new Date(moment(_C.date).add(CONSTANTS.JOIN_EXPIRES, 'minutes')) });
                setTimeout(() => startMovie(), 1000)
            }
            return newTime < 0 ? 0 : newTime
        })
    }

    return <div className="sd_wr_banner sd_home_count ">
        {!props.non_film ?
            <>
                <div className="sd_main_screening ">
                    <div className="sd_inner_container sd_inner_screeing">
                      {/* Link with image */}
                      <div className='sd_wpr_count_column sd_flex'>
                            {partner && _STATUS !== 'Coming Soon' ? <div className="sd_logo_sec sd_flex">
                                    <p className="sd_textuppercase">This Screening is Brought to you by</p>
                                    {partner && partner.partnerLogo ?  <img src={partner.partnerLogo && !partner.partnerLogo.includes('http') ? process.env.REACT_APP_IMAGE_URL + partner.partnerLogo :
                                        partner.partnerLogo && partner.partnerLogo.includes('http') ? partner.partnerLogo : ''} alt="Sponsors" title="Sponsors" /> : <h3>{partner.partnerName}</h3>}
                            </div> : ''}
                            {_STATUS === 'Now Playing' ?

                                <div className="sd_live_qa">
                                    {_C?.screen?.filmId?.isAudioDescription ? <div className="sd_video_audio sd_video_screen_link">
                                        <React.Fragment>
                                            <img src={_AUDIO ? "/images/no_audio_desc_icon.svg" : "/images/audio_desc_small_icon.svg"}
                                                alt="Watch without Audio Description" title="Watch without Audio Description" />
                                            <Link onClick={playMovie}>
                                                Watch {_AUDIO ? 'Without' : 'With'} Audio Description
                                                <img src="/images/slider_arrow_right.svg" alt="Watch without Audio Description"
                                                    title="Watch without Audio Description" />
                                            </Link>
                                        </React.Fragment>
                                    </div> : <div className="sd_video_audio sd_video_screen_link"></div>}
                                    {/* {<h4 className="">Q&amp;A after the Film</h4>} */}
                                </div> : ''
                            }
                            
                        </div>
                        <div className="sd_count_down_sec">
                            {/* {partner && _STATUS !== 'Coming Soon' ? <div className="sd_logo_sec sd_flex">
                                <p className="sd_textuppercase">This Screening is Brought to you by</p>
                                 {partner && partner.partnerLogo ?  <img src={partner.partnerLogo && !partner.partnerLogo.includes('http') ? process.env.REACT_APP_IMAGE_URL + partner.partnerLogo :
                                    partner.partnerLogo && partner.partnerLogo.includes('http') ? partner.partnerLogo : ''} alt="Sponsors" title="Sponsors" /> : <h3>{partner.partnerName}</h3>}
                            </div> : ''} */}
                            <div className="sd_count_down_wpr sd_flex">
                                <span className="sd_textuppercase">{title}</span>
                                {_STATUS === 'Coming Soon' && !isMoreTime && time > 0 ?
                                    <Timer time={time} SortTime={true} updateTime={movieCountDown} />
                                    :
                                    <h2 className="sd_textuppercase">{_STATUS === 'End' ? (_MOVIE_STATUS === 'continuous' ? 'Live Q&A' : 'Q&A Replay') : isMoreTime && _C?.screen?.screeningDate ? moment(_C?.screen?.screeningDate).format('MMMM DD, hh:mm A') : _STATUS}</h2>}
                            </div>
                        </div>

                      
                    </div>
                </div>
            </>
            :
            <div className="sd_count_down_sec sd_count_down_non_film">
                <div className="sd_inner_container sd_count_down_wpr">
                    <h1 className="sd_textuppercase"> Non film event name lorem ipsum dolor </h1>
                </div>
            </div>
        }

    </div>

})

export default HeroHeader;
