import React from 'react';

// Components
import {useSelector} from "react-redux";
import ChatMessages from "./ChatMessage";
import ChatInput from "./ChatInput";

const Chat = React.memo((props) => {
    const _C_S = useSelector(e => e.chatStatus);
    return (
        <React.Fragment>
            <div className="sd_chat_wpr">
               {props.showHeader && <div className="sd_chat_header">
                    <h2 className="sd_textuppercase">Chat</h2>
                </div>}
                <div className="sd_chat_content">
                    {_C_S === 1 && <React.Fragment>
                        <ChatMessages/>
                        <ChatInput/>
                    </React.Fragment>
                    }
                     {_C_S === 2 && <p className="sd_chat-note">Chat for this event is closed.</p>}
                </div>
            </div>

        </React.Fragment>
    )
})

export default Chat;