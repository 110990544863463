import React, { Component } from "react";

export default class InputField extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { placeholder, label, type, name, value, disable, require, readonly, required, onBlur, pattern, autoComplete, autoFocus } = this.props;
		return (
			<div className="field_row_inner">
				{
					label ?						
						<label>
							{label} {require ? <span>*</span> : <span></span>}
							
							{required ? 
								<sup>
									<img src="/images/asterisk_icon.svg" alt="Required" title="Required" />
								</sup>
							: 
									''
								}
						</label>
					: 
						''
				}
				<div className="field_input relative">
					<input
						type={type}
						name={name}
						value={value}
						placeholder={placeholder}
						disabled={disable}
						pattern={pattern}
						readOnly={readonly}
						onChange={this.props.onChange}
						onBlur={onBlur}
						autocomplete={autoComplete}
						autoFocus={autoFocus}
					/>
				</div>
			</div>
		);
	}
}
