import React, { Component } from "react";
import service from "../common/service";
import { Link } from "react-router-dom";
import moment from 'moment';
import Cookies from "universal-cookie";
const cookies = new Cookies();

class UserProfile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			date: new Date(),
		};
		this.apiserve = new service();
  	}
	componentDidMount() {
  
		this.getCurrentTime();
  
		setInterval(() => {
			this.getCurrentTime();
			this.setState({
				date: new Date(),
			});
		}, 1000);
		setTimeout(() => {
			// this.props.updateCartCount()
		  }, 400);
	}

	// Get Current Time
	getCurrentTime = () => {
		let hours, minutes;
		hours = this.state.date.getHours();
		hours = hours % 12;
		hours = hours ? hours : 12;
		minutes = this.state.date.getMinutes();
		minutes = minutes <= 9 ? `0${minutes}` : minutes;
		return `${hours}:${minutes}`;
	};

	logout = (e) => {
        e.preventDefault();
         //eslint-disable-next-line
         document.cookie = "privateScreeningUser" + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
         cookies.remove("privateScreeningUser", { domain: process.env.REACT_APP_COOKIE_DOMAIN, path: "/" });
        //eslint-disable-next-line
        document.cookie = "SundanceUserProfile" + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
        cookies.remove("SundanceUserProfile", { domain: process.env.REACT_APP_COOKIE_DOMAIN, path: "/" });
		
        //eslint-disable-next-line
        document.cookie = "fromTrailer" + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
        cookies.remove("fromTrailer", { domain: process.env.REACT_APP_COOKIE_DOMAIN, path: "/" });
		if (cookies.get("SundanceNewUser")) {
			// eslint-disable-next-line
			document.cookie = "SundanceNewUser" + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
			cookies.remove("SundanceNewUser", { domain: process.env.REACT_APP_COOKIE_DOMAIN, path: "/" });
		} else if (cookies.get("particularNewUser")) {
			// eslint-disable-next-line
			document.cookie = "particularNewUser" + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
			cookies.remove("particularNewUser", { domain: process.env.REACT_APP_COOKIE_DOMAIN, path: "/" });
		}
		cookies.remove("USERHeader", { domain: process.env.REACT_APP_COOKIE_DOMAIN, path: "/" });
		let Zone = localStorage.getItem('TIMEZONE')
		localStorage.clear();
		sessionStorage.clear()

	if(Zone) localStorage.setItem('TIMEZONE', Zone)
        setTimeout(() => {
             this.props.history.push('/sign-in');
        }, 300);
    };

	gotoProfile(e, to) {
		window.open(`${process.env.REACT_APP_HOME_URL}my-account?tab=${to}`, "_self");
	}

	cartPage(e) {
		window.open(`${process.env.REACT_APP_HEADER_BUY_TICKETS}cart`, "_self");
	}

	render() {
		// State
		let { date } = this.state;
		// let userData = JSON.parse(localStorage.getItem("Header"));
        var userData = {}; 
        if(this.props && this.props.userInfo){
			userData =  this.props.userInfo;
		}
		
		let SundanceNewUser = cookies.get("SundanceNewUser");
		// let cartCount = cookies.get("CartCount");

		return (
			<div className="sd_user_wpr sd_flex sd_aligncenter">
			{/* Profile */}
				{ userData && userData.fname &&
				<div className="sd_user_profile">
					<div className="sd_user_profile_wpr sd_flex sd_aligncenter">
						<div className="sd_user_profile_user sd_flex sd_aligncenter">
						
							<Link className="sd_flex sd_aligncenter" onClick={(e) => this.gotoProfile(e, 0)}>
								<div className="sd_user_profile_image">
									<img src={ userData?.image ?  this.apiserve.imageUrl + userData.image : `/images/user_profile_logo.svg`} alt={userData && userData.fname} title={userData && userData.fname}/> 
								</div>
								{   
									userData && userData.fname && 
									<p title={userData && userData.fname}>Hi, {userData && userData.fname ? userData.fname : ' ' + userData && userData.lname ? userData.lname :''}</p>
								}
							</Link>
						
						
								{/* Nav Options */}{
								userData && userData.fname &&  <div className="sd_user_profile_menus sd_user_profile_m_menu sd_hidden">
									<ul>
										{/* <li>
											<Link onClick={(e) => this.gotoProfile(e, 2)}>
												<img src={`/images/resources_icon.svg`} alt="My Purchases" title="My Purchases"/>
												My Purchases    
											</Link>
										</li>
										<li>
											<Link onClick={(e) => this.gotoProfile(e, 1)}>
												<img src={`/images/my_schedule_icon.svg`} alt="My Schedule" title="My Schedule"/>
												My Schedule
											</Link>
										</li>
										<li>
											<Link onClick={(e) => this.gotoProfile(e, 3)}>
												<img src={`/images/settings_icon.svg`} alt="Settings" title="Settings"/>
												Settings
											</Link>
										</li> */}
										<li>
											<Link onClick={this.logout}>Sign out</Link>
										</li>
									</ul>
								</div>
							}                          
					
						</div>  
						{  
            	window.location.host.includes("festival.sundance.org") || window.location.host.includes("staginghome.sundance.org")  || window.location.host.includes("digital.sundance.org") ? '' : <>
						{
							SundanceNewUser ? 
							<div className={`sd_user_profile_cart sd_flex ${this.props.isActive ? 'active' : ''}`}>
                			<div className="sd_calender">
								<div className='sd_calender_img'>
									<Link onClick={(e) => this.gotoProfile(e, 2)}>
									<img src={`/images/calendar.png`} alt="calendar" title="calendar"/>
									</Link>
								</div>
							</div>
							<div className="sd_cart_btn">
								<button onClick={window.innerWidth <= 768 ? (e) => this.cartPage(e) : (e) => this.cartPage(e)}>
									<img src="/images/cart.png" alt="Cart" title="Cart" />
									{ 
										this.props.cartCount ? 
											<span className="sd_label sd_label_yellow"> {this.props.cartCount} </span>
										: ''
									}	 
								</button>
							</div>
							<div class="sd_menu_search" onClick={() => this.props.isSearchOpen()}><img src={`/images/search.png`}  alt="search" title="search"/></div>
                           </div>
							: ''
						}
						</>
              }
						
					</div>

				</div> }
				{/* Nav Options */}
				<div className="sd_user_profile_menus sd_hidden">
					<ul>
						<li>
							<Link onClick={(e) => this.gotoProfile(e, 2)}>
								<img src={`/images/resources_icon.svg`} alt="My Purchases " title="My Purchases    "/>
								My Purchases    
							</Link>
						</li>
						<li>
							<Link onClick={(e) => this.gotoProfile(e, 1)}>
								<img src={`/images/my_schedule_icon.svg`} alt="My Schedule" title="My Schedule"/>
								My Schedule
							</Link>
						</li>
						<li>
							<Link onClick={(e) => this.gotoProfile(e, 3)}>
								<img src={`/images/settings_icon.svg`} alt="Settings" title="Settings"/>
								Settings
							</Link>
						</li>
						<li>
							<Link onClick={this.logout}>Sign out</Link>
						</li>
					</ul>
					<div className="sd_user_profile_timezone sd_hidden">
						<div className="sd_user_timezone_time">
							<p>{ this.getCurrentTime() } </p>
							<div className="sd_user_timezone_select">
								<p>{this.props.timezone}</p>
							</div>
						</div>
						<div className="sd_user_timezone_date">
							<span className="sd_textuppercase">{moment(date).format("MMMM") }</span>
							<h3>{ moment(date).format("DD") }</h3>
						</div>
					</div>
				</div>

			</div>
    	);
  	}
}

export default UserProfile;
