import React, { Component } from 'react';

// Video-React - Package
import 'video-react/dist/video-react.css';
import { Player, ControlBar, BigPlayButton, PlayToggle, VolumeMenuButton } from 'video-react';

class VideoPlayer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			videoSrc: '',
			getTime: '',
			duration: '',
			player: '',
			currentTime: '',
			endvideo: '',
			isPause: false
		}
		this.load = this.load.bind(this);
		this.play = this.play.bind(this);
	}

	load = () => {
		this.player.load();
	}

	play = () => {
		this.player.load();
		this.player.play();
	}

	componentDidMount = () => {
		this.player.subscribeToStateChange(this.handleStateChange);
	}

	handleStateChange = (state) => {
		this.setState({
			player: state,
			endvideo: state.ended,
			duration: state.duration,
			currentTime: state.currentTime,
			isPause: state.paused
		});

		if (state.ended) {
			this.props.videoEnded(state.ended);
		}
	}

	render() {
		// Props
		let { autoplay } = this.props;

		return (
			<Player className="vid_wrap" autoPlay={autoplay} muted={autoplay} ref={(player) => { this.player = player }}>
				<source src={this.props.videoSrc} />
				<BigPlayButton position="center" />
				<ControlBar autoHide={false} className="controls_custom">
					<PlayToggle />
					<VolumeMenuButton />
				</ControlBar>
			</Player>
		);
	}
}

export default VideoPlayer;