import React, { Component } from 'react';
import service from '../common/service'

// Layout
import Header from '../layout/Header';
import Footer from '../layout/Footer';

// Components
import TextField from "../components/TextField";
import PwdField from "../components/PwdField";
import Popup from "../components/Popup";

import SimpleReactValidator from "simple-react-validator";
let sub
class ResetPasword extends Component {
    constructor() {
		super();
		this.state = {
			popup: false,
			form_popup: false,
			popup_title: "",
			popup_note: [],
			email: "", 
			verification:"",
			password: "", 
			confirmPassword: "",
			errorMsg:[],
			passwordErr:'', 
			emailErr:'',
			passwordMustSameError:''
		};
		this.apiserve = new service();
		this.validator = new SimpleReactValidator({ autoForceUpdate: this });
	}

	closePopup = () => {
		this.setState({
			popup:false,
			errorMsg:[]
		})
	}

	onInputChange = (e) => {
		const {name,value} = e.target
		this.setState({
			[name] : value
		})
	}

	componentWillUnmount = () => {
		sub.unsubscribe();
	  };

	componentDidMount() {
		let paramsData = this.props.location.search
		if(paramsData){
			paramsData = paramsData.split('id=')
			paramsData = paramsData[1].split('&email=')
			   this.setState({
				verification:paramsData[0],
				email:paramsData[1]
			})
		}
		sub = this.apiserve.getevent().subscribe((response) => {
			switch (response.event) {
			case "privateScreeningPartner|front|resetPassword":
				if(!response.error) { 
					this.props.history.push('/sign-in')
				}
				break;
			default:
				break;
			}
		  });
	}

	validate = () => {
        let emailErr = ''
        let passwordErr = '';
        let passwordMustSameError = ''
        if (!this.state.password) {
            passwordErr = 'Password can not be blank.'
        }
        else {
			// eslint-disable-next-line 
            if (!this.state.password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d[\]{};:=<>_+^#$@!%*?&]{8,30}$/)) {
                passwordErr = 'Password must be at least 8 characters and include one lowercase letter, one uppercase letter, and one digit.';
            }
        }
        if (!this.state.confirmPassword) {
            passwordMustSameError = 'Confirm Password can not be blank.'
        }else{
            if(this.state.password !== this.state.confirmPassword){
                passwordMustSameError = 'Password and Confirm Password do not match.'
              }
        }
        if (!this.state.email) {
            emailErr = 'This field is required.'
          }else{
			  // eslint-disable-next-line 
            if (!this.state.email.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
              emailErr = 'Email address must be valid email.';
          }
        } 
        this.setState({
            passwordErr, emailErr,passwordMustSameError
		})
		
		if (passwordErr || emailErr || passwordMustSameError) {
			return false
		}
		return true
       
    }

	resetPassword = (e) => {
		e.preventDefault();
		const isValid = this.validate();
        if (isValid) {
		  let resetPsdData = {
			verification: this.state.verification,
			password:this.state.password,
		  };

		  this.apiserve.sendevent("privateScreeningPartner|front|resetPassword", resetPsdData);
		} else {
		  this.validator.showMessages();
		  this.forceUpdate();
		  setTimeout(() => {
			this.openMsg();
		  }, 50);
		}
	}
	openMsg=()=>{
		this.setState({errorMsg:[this.state.emailErr, this.state.passwordErr, this.state.passwordMustSameError]},()=>{this.setState({popup:true})})
	}
    render() {
		// State
		const { popup , email, password, confirmPassword,errorMsg} = this.state;
        return ( 
			<>
			{/* Header */}
			<Header isMenuShow={false} />

            <div className="home reset-password">
               <section className="sd_user_form_section sd_signin_form_section">
					<div className="sd_inner_container">

						{/* <div className="banner_display_contant">
                            <h2 className="sd_textuppercase">Sundance Institute presents</h2>
                            <h1 className="sd_textuppercase">Sundance Film Festival 2021</h1>
                        </div> */}

						<div className="sd_user_form sd_signin_form">
							<div className="sd_user_form_header sd_reset_form_header">
								<h3 className="sd_textuppercase">Reset Password</h3>
							</div>

							<form>
								<div className="sd_form_group">
									<TextField type="text" label="email" name="email" value={email} onChange={(e)=>this.onInputChange(e)} disable={true}/>							
								</div>

								<div className="sd_form_group sd_form_pwd_group">
									<PwdField label="Password" name="password" value={password} onChange={(e)=>this.onInputChange(e)} />
									<span style={{ color: 'white' }} className='srv-validation-message'> {this.state.passwordErr}</span>
								</div>

								<div className="sd_form_group sd_form_pwd_group">
									<PwdField label="Confirm Password" name="confirmPassword" value={confirmPassword} onChange={(e)=>this.onInputChange(e)}/>
									<span style={{ color: 'white' }} className='srv-validation-message'>{this.validator.message('confirm_password', this.state.confirmPassword, `required|in:${this.state.password}`, {messages: {in: 'Password and Confirm Password does not match.'}})}</span>
								</div>
								
								<div className="sd_form_submit reset_btn">
									<input className="sd_btn sd_btn_black" type="submit" value="Submit" onClick={(e) => this.resetPassword(e)} />
								</div>
							
							</form>
						</div>
					</div>
        		</section>
            </div>

			{/* footer */}
			<Footer />
			{/* {
				popup && errorMsg.length > 0 ? 
					<Popup
						title=''
						popup_note={this.state.errorMsg}
						form={false}
						onChildClick={this.closePopup}
					/>
				: ''
			} */}
			</>
        )
    }
}

export default ResetPasword;