import React, { Component } from 'react';
import service from "../common/service";

// Layout
import Footer from '../layout/Footer';
import Header from '../layout/Header';

// Components
import FilmCard from '../components/FilmCard';
import Cookies from 'universal-cookie';
// import { withRouter } from 'react-router-dom';
import Loader from '../components/loader';
import { connect } from "react-redux";
import WatchEndPopup from '../components/WatchEndPopup';
const cookies = new Cookies();
let sub;


const mapStateToProps = state => ({
    _STATUS: state.status,
    _AGE: state.age,
    FIVE_HR: state.fiveHours,
    _MOVIE_STATUS: state.movieStatus,
    _C: state.core,
    _C_S: state.chatStatus,
    _QA: state.showQnA,
    _Q_S: state.questionStatus,
    _IS_MORE_TIME: state.isMoreTime,
    _SP: state.showPlayer

});
class MyScreening extends Component {
    constructor(props) {
        super(props);

        this.state = {
            Screening: [],
            isLoading:false,
            watchPopup:false
        };
        this.apiserve = new service();
    }

    componentWillUnmount = () => {
       if(sub) sub.unsubscribe();
    };

    componentDidMount() {
        let user = cookies.get("privateScreeningUser");
        this.props.dispatch({ type: 'SET_FIVE_HOUR_WINDOW_STATUS', payload: '' })
        if(!user){
            this.props.history.push('/sign-in') 
        }else{
             // eslint-disable-next-line
            let AT = cookies.get('privateScreeningAT');
            // this.apiserve.sendevent("authentication", { token: AT }); 
            setTimeout(() => {
                this.getScreening();
          }, 1000);
          setTimeout(() => {
              this.getEventData();
          }, 1000);
        }
    }
    getScreening = () => {
        // let data = {
        //     limit: 10,
        //     page: 1
        // }
        this.setState({
            isLoading:true
        })
        this.apiserve.sendevent("privateScreeningPartner|front|PS_09_MyScreening", {});
    }

    getEventData=()=>{
        sub = this.apiserve.getevent().subscribe((response) => {
            switch (response.event) {
                case "privateScreeningPartner|front|PS_09_MyScreening":
                    if (!response.error) {
                        this.setState({Screening:[]},()=>{
                            this.setState({
                                Screening: response.data
                            })
                        })
                    }
                    this.setState({
                        isLoading:false
                    })
                    break;
                default:
                    break;
            }
        });
        }
    

    render() {
        // State

        return (
            <div className="my_screening">
                {/* Header */}
                <Header isMenuShow={true} history={this.props.history} />
                <div className="sd_main_screening_title">
                    <div className="sd_inner_container">
                        <div className="sd_screening_title sd_textuppercase">
                            <h1>My Private Screenings</h1>
                        </div>
                    </div>
                </div>

                <div className="sd_my_screening">
                    <div className="sd_inner_container">
                       
                        {
                            this.state?.Screening && this.state?.Screening?.map(item => {
                                var parnter = (item && item.Users[0] && item.Users[0].updatedBy) || undefined;
                                return (
                                    <div className="sd_screening_blog">
                                        <div className="sd_filmcard_title ">
                                           {
                                               parnter && parnter.partnerLogo ? <img src={parnter.partnerLogo && !parnter.partnerLogo.includes('http') ? this.apiserve.imageUrl + parnter.partnerLogo : 
                                               parnter.partnerLogo && parnter.partnerLogo.includes('http') ? parnter.partnerLogo : '/images/placeholder.png'} alt="Film card" title="Film card" /> 
                                               : <h3>{parnter.partnerName}</h3>
                                           }
                                        </div>
                                        <div className="sd_filmcard">
                                            {
                                                item && item.Users && item.Users.length > 0 && item.Users.map(user=>{
                                                    return(
                                                          <FilmCard data={user} apiUrl={this.apiserve.imageUrl}/>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </div>
                </div>
                {/* Footer */}
                <Footer />

                {
                    this.state.isLoading ? <Loader /> : ''
                }
                {
                    this.state.watchPopup ? <WatchEndPopup /> : ''
                }
            </div>
        )
    }
}

export default connect(mapStateToProps, null)(MyScreening);