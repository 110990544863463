import React, { Component } from 'react';
import service from "../common/service";
// Layout
import Header from '../layout/Header';
import Footer from '../layout/Footer';

// Components
import TextField from "../components/TextField";
import PwdField from "../components/PwdField";
import Popup from "../components/Popup";
import SimpleReactValidator from "simple-react-validator";
import Cookies from 'universal-cookie';
import ArrowRightIcon from "../images/arrowRightIcon";


const cookies = new Cookies();
let sub;

class SignIn extends Component {
	constructor() {
		super();
		this.state = {
			popup: false,
			hidepass: false,
			form_popup: false,
			popup_title: "",
			popup_note: [],
			screenId: '',
			invitedData: {
				isInvited: false,
				invitationLink: ''
			},
			user: {
				email: "",
				password: "",
				role: ""
			},
			forgotPassword: {
				email: "",
			},
			isError: false,
			errorMsg: ''
		};
		this.apiserve = new service();
		this.validator = new SimpleReactValidator({ autoForceUpdate: this });
	}

	componentWillUnmount = () => {
		sub.unsubscribe();
	};

	componentDidMount() {
		window.scroll(0, 0);
		const referralCode = this.props.match.params.referralCode;
		const screenKey = this.props.match.params.screenKey;
		const screenIds = this.props.match.params.screenId;
		if (referralCode && screenKey && screenIds) {
			this.setState({
				screenId: screenIds, invitedData: {
					isInvited: true,
					referralCode: this.props.match.params.referralCode,
					screenKey: this.props.match.params.screenKey,
					screenIds: this.props.match.params.screenId,
				}
			});
		}
		if (this.props.location && this.props.location.search && this.props.location.search.includes('screenId')) {
			// var id = this.props.location.search.split('=');
			const urlSearchParams = new URLSearchParams(window.location.search);
			const params = Object.fromEntries(urlSearchParams.entries());
			if (params && params.screenId.length > 1) {
				this.setState({ screenId: params.screenId });
			}
		}
		if (this.props.location && this.props.location.search && this.props.location.search.includes('referralCode')) {
			const urlSearchParams = new URLSearchParams(window.location.search);
			const params = Object.fromEntries(urlSearchParams.entries());
			if (params && params.referralCode.length > 1) {
				this.apiserve.sendevent("privateScreeningPartner|cms|getEmail", { referralCode: params.referralCode });
			}
		}

		let user = cookies.get("privateScreeningUser")
		if (user) {
			if (this.props.location && this.props.location.search && this.props.location.search.includes('screenId')) {
				// let id = this.props.location.search.split('=');
				const urlSearchParams = new URLSearchParams(window.location.search);
				const params = Object.fromEntries(urlSearchParams.entries());
				if (params && params.screenId.length > 1) {
					this.props.history.push("/myscreening");
				}
				// if (id && id.length > 1) {
				// 	this.props.history.push("/myscreening");
				// 	// this.props.history.push("/program-detail/" + id[1]);
				// 	// this.setState({ screenId: id[1] });
				// }
			} else {
				this.props.history.push("/myscreening");
			}
			// this.props.history.push('/myscreening');
		}
		let loginData = {}
		sub = this.apiserve.getevent().subscribe((response) => {
			switch (response.event) {
				case "privateScreeningPartner|front|login":
					cookies.set("myLoginToken", response.data.token, { domain: process.env.REACT_APP_COOKIE_DOMAIN,path: "/" });
					if (!response.error) { 
						this.apiserve.sendevent("user|thirdPartyToken", {
							id: response.data.user._id,
							redirectLink: 'https://api.eventive.org/auth/sundance/callback',
						});
						loginData = { ...response.data }
						
						this.apiserve.sendevent("authentication", { token: response.data.token }); }
					else {
						this.setState({ isError: true, errorMsg: [response.data.error] })
					}
					break;
				case "user|thirdPartyToken":
					if (!response.error) {
						let auth = new URL(response.data.URL);
						let token = auth.searchParams.get("auth");
						token = decodeURIComponent(token)
						window.Eventive.loginSundance({
							userToken:token
						})
						.then(order => {
							console.log(order,"oreeee",);
							if (!order) {
								return;
							}
							this.apiserve.sendevent("authentication", {
								token: cookies.get("myLoginToken"),
							});
							cookies.set("myEventiveLoginToken", order.token, {domain: process.env.REACT_APP_COOKIE_DOMAIN, path: "/" });
							this.apiserve.sendevent("user|updateEventiveDeatils", {email:order.details.email,id:order.id});
						})
						.catch(err => {
							console.log(err,"Errr");
						});
					} else {
						this.setState({
							// closeOkModal: true,
							closeMsg: response.data.error,
						});
					}
					break;
				case "privateScreeningPartner|front|getProfile":
					if (!response.error) {
						let userInfo = {
							fname: response.data.fname,
							lname: response.data.lname,
							image: response.data.image,
							showMyLibrary: response.data.showMyLibrary
						};
						cookies.set("SundanceUserProfile", userInfo, { 
							domain: process.env.REACT_APP_COOKIE_DOMAIN,
							 path: "/" });
						if (this.state.screenId) {
							this.props.history.push("/myscreening");
							// this.props.history.push("/program-detail/" + this.state.screenId);
						} else {
							this.props.history.push("/myscreening");
						}

					} else {
						this.setState({ isError: true, errorMsg: [response.data.error] })
					}

					break;
				case "privateScreeningPartner|cms|getEmail":
					debugger
					if (!response.error) {
						const User = response.data;
						this.setState({
							user: {
								...this.state.user,
								email: User.email,
								password: ''
							}
						})
					}
					break;
				default:
					break;
			}
		});
	}

	Forgotpassword = () => {
		this.setState({
			popup: !this.state.popup
		})
	}

	handleForgotEmail = (e) => {
		if (e.target.value) {
			this.setState({
				forgotEmailEmptyError: "",
			});
		} else {
			this.setState({
				forgotEmailEmptyError: "Please enter your email.",
			});
		}

		this.setState({
			forgotEmail: e.target.value,
		});
	};

	closePopup = (flag) => {
		if (flag === false) {
			this.setState({
				popup: false,
				isError: true,
				errorMsg: ['Reset email has been sent, Please check your email.']
			})
		} else {
			this.setState({
				popup: false,
				isError: false,
				errorMsg: ''
			})
		}
	}

	handleChange = (e) => {
		const { name, value } = e.target;

		this.setState({
			user: {
				...this.state.user,
				[name]: value.trim(),
			},
		});
	};

	validate = () => {
		let emailErr = ''
		let passwordErr = '';

		if (!this.state.user.password && !this.state.user.email) {
			passwordErr = 'Email and Password can not be blank.'
		} else if(!this.state.user.password) {
			passwordErr = 'Password can not be blank.'
		} else if (!this.state.user.email) {
			emailErr = 'Email can not be blank.'
		} else {
			// eslint-disable-next-line 
			if (!this.state.user.email.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
				emailErr = 'Email address must be valid email.';
			}
		}

		this.setState({
			passwordErr, emailErr
		})
		if (passwordErr || emailErr) {
			return false
		}
		return true
	}

	signInClicked = async (e) => {
		e.preventDefault();
		let isValid = this.validate();
		if (isValid) {
			let passData = {
				email: this.state.user.email,
				password: this.state.user.password,
			};

			if (this.state.screenId) {
				passData.screenId = this.state.screenId
			}
			this.apiserve.sendevent("privateScreeningPartner|front|login", passData);
		} else {
			this.validator.showMessages();
			this.forceUpdate();
			setTimeout(() => {
				this.openMsg()
			}, 20);
		}
	};
	openMsg = () => {
		this.setState({ errorMsg: [this.state.passwordErr, this.state.emailErr] }, () => { this.setState({ isError: true }) })
	}
	CreateAccount = () => {
		if (this.state.invitedData.isInvited) {
			const { referralCode, screenKey, screenIds } = this.state.invitedData
			this.props.history.push(`/register/${referralCode}/${screenKey}/${screenIds}`)
		} else this.props.history.push('/register')
	}
	render() {
		// State
		const { user, popup, isError, errorMsg } = this.state;

		return (
			<>
				{/* Header */}
				<Header isMenuShow={true} history={this.props.history} />

				<div className="home">
					{/* <img src="/images/home_bg.png" /> */}
					<section className="sd_user_form_section sd_signin_form_section">
						<div className="sd_inner_container">

							{/* <div className="banner_display_contant">
								<h2 className="sd_textuppercase">Sundance Institute presents</h2>
								<h1 className="sd_textuppercase">Sundance Film Festival 2021</h1>
							</div> */}

							<div className="sd_user_form sd_signin_form">
								<div className="sd_user_form_header sd_signin_form_header">
									<h3 className="">To complete your registration for your Private Screening, sign in to your Sundance Institute Account</h3>
								</div>
								<form onSubmit={(e) => this.signInClicked(e)} autocomplete={false} className="sd_signin_form">
									<div className="sd_form_group">
										<TextField
											name="email"
											value={user.email}
											onChange={this.handleChange}
											label="Email"
											type="text"
											placeholder=""
											autoComplete='off'
											autoFocus={true}
										/>
										<span style={{ color: "red" }}>
											{this.validator.message(
												"email",
												user.email,
												"required|email"
											)}
										</span>
									</div>

									<div className="sd_form_group sd_form_pwd_group">
										<PwdField onChange={this.handleChange}
											value={user.password}
											name="password"
											label="Password"
											type="password"
											hidepass={this.state.hidepass}
											HideShowPass={this.HideShowPass}
											autoComplete='off'
										/>
										<span style={{ color: "red" }}>
											{this.validator.message(
												"password",
												user.password,
												"required"
											)}
										</span>
									</div>

									<div className="sd_form_link">
										<span className="link_btn pointer" onClick={this.Forgotpassword}>Forgot Password?</span>
									</div>

									<div className="sd_form_submit sign_btn">
										<input className="sd_btn sd_btn_black" type="submit" value="Submit" onClick={(e) => this.signInClicked(e)} />
									</div>
								</form>
								<div className="sd_Create_an_Account">
									<i>or </i> <span className="create_link_btn pointer" onClick={() => this.CreateAccount()}> Create an Account <ArrowRightIcon /></span>
								</div>
							</div>

						</div>
					</section>
				</div>

				{/* footer */}
				<Footer />

				{
					popup ?
						<Popup
							title='Forgot Password'
							popup_note={['Please provide the email address you used to sign up, and we’ll send you instructions to reset your password.']}
							form={true}
							onChildClick={this.closePopup}
						/>
						: ''
				}
				{
					isError && errorMsg ?
						<Popup
							title=''
							popup_note={errorMsg}
							form={false}
							onChildClick={this.closePopup}
						/>
						: ''
				}





			</>
		)
	}
}

export default SignIn;